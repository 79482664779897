import { createFormAction } from 'redux-form-saga'
export const PROFILE_FORM_KEY = 'PROFILE_FORM'
export const ProfileFormAction = createFormAction(PROFILE_FORM_KEY)

export const GENERAL_FORM_KEY = 'GENERAL_FORM'
export const GeneralFormAction = createFormAction(GENERAL_FORM_KEY)

export const INFO_JOB_FORM_KEY = 'INFO_JOB_FORM'
export const InfoJobFormAction = createFormAction(INFO_JOB_FORM_KEY)

export const CONTACT_FORM_KEY = 'CONTACT_FORM'
export const ContactFormAction = createFormAction(CONTACT_FORM_KEY)

export const FAMILY_FORM_KEY = 'FAMILY_FORM'
export const FamilyFormAction = createFormAction(FAMILY_FORM_KEY)

export const WORKING_FORM_KEY = 'WORKING_FORM'
export const WorkingFormAction = createFormAction(WORKING_FORM_KEY)

export const CONTRACT_FORM_KEY = 'CONTRACT_FORM'
export const ContractFormAction = createFormAction(CONTRACT_FORM_KEY)

export const CERTIFICATE_FORM_KEY = 'CERTIFICATE_FORM'
export const CertificateFormAction = createFormAction(CERTIFICATE_FORM_KEY)

export const PREFERENCE_FORM_KEY = 'PREFERENCE_FORM'
export const PreferenceFormAction = createFormAction(PREFERENCE_FORM_KEY)

export const FILE_FORM_KEY = 'FILE_FORM'
export const FileFormAction = createFormAction(FILE_FORM_KEY)

export const AWARD_FORM_KEY = 'AWARD_FORM'
export const AwardFormAction = createFormAction(AWARD_FORM_KEY)

export const SEARCH_CONTACT_FORM_KEY = 'SEARCH_CONTACT_FORM'
export const SearchContactFormAction = createFormAction(SEARCH_CONTACT_FORM_KEY)

export const LIST_EMPLOYEE_FORM_KEY = 'LIST_EMPLOYEE_FORM'
export const ListEmployeeFormAction = createFormAction(LIST_EMPLOYEE_FORM_KEY)
