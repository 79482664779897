import { createAction, handleActions } from 'redux-actions';
// - Actions
export const autoCompleteCustomerRequest = createAction('AUTO_COMPLETE_CUSTOMER_REQUEST');
export const autoCompleteCustomerSuccess = createAction('AUTO_COMPLETE_CUSTOMER_SUCCESS');

export const getStationsRequest = createAction('GET_STATIONS_REQUEST');
export const getStationsSuccess = createAction('GET_STATIONS_SUCCESS');

export const getStaffsRequest = createAction('GET_STAFFS_REQUEST');
export const getStaffsSuccess = createAction('GET_STAFFS_SUCCESS');

export const getPartnersRequest = createAction('GET_PARTNERS_REQUEST');
export const getPartnersSuccess = createAction('GET_PARTNERS_SUCCESS');

export const getCustomerFullInfoRequest = createAction('GET_CUSTOMER_FULL_INFO_REQUEST');
export const getCustomerFullInfoSuccess = createAction('GET_CUSTOMER_FULL_INFO_SUCCESS');

export const getStaffWithDepartmentRequest = createAction('GET_STAFF_WITH_DEPARTMENT_REQUEST');
export const getStaffWithDepartmentSuccess = createAction('GET_STAFF_WITH_DEPARTMENT_SUCCESS');

export const getDepartmentsRequest = createAction('GET_DEPARTMENTS_REQUEST');
export const getDepartmentsSuccess = createAction('GET_DEPARTMENTS_SUCCESS');

export const getFamilyRelationshipRequest = createAction('GET_FAMILY_RELATIONSHIP_REQUEST');
export const getFamilyRelationshipSuccess = createAction('GET_FAMILY_RELATIONSHIP_SUCCESS');

export const getCertificateTypeRequest = createAction('GET_CERTIFICATE_TYPE_REQUEST');
export const getCertificateTypeSuccess = createAction('GET_CERTIFICATE_TYPE_SUCCESS');

export const getPeoplesRequest = createAction('GET_PEOPLES_REQUEST');
export const getPeoplesSuccess = createAction('GET_PEOPLES_SUCCESS');

export const failure = createAction('ACTION_COMMON_FAILURE');


// - Initial State
export const initialState = {
  customers: [],
  customering: false,

  stations: [],
  stationsing: false,

  staffs: [],
  staffsing: false,

  partners: [],
  partnersing: false,

  staffWithDepartments: [],
  fetchingStaffWithDepartment: false,

  departments: [],
  departmentsing: false,

  relationships: [],
  fetchingRelationship: false,

  certificateTypes: [],
  fetchingCertificateType: false,

  peoples: [],
  fetchingPeoples: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [autoCompleteCustomerRequest]: (state) => {
    return { ...state, customers: [], customering: true };
  },
  [autoCompleteCustomerSuccess]: (state, { payload: { customers } }) => {
    return { ...state, customers: customers || [], customering: false };
  },

  [getStationsRequest]: (state) => {
    return { ...state, stationsing: true };
  },
  [getStationsSuccess]: (state, { payload: { stations } }) => {
    return { ...state, stationsing: false, stations: stations, };
  },

  [getStaffsRequest]: (state) => {
    return { ...state, staffsing: true };
  },
  [getStaffsSuccess]: (state, { payload: { staffs } }) => {
    return { ...state, staffsing: false, staffs: staffs, };
  },

  //Panners
  [getPartnersRequest]: (state) => {
    return { ...state, partnersing: true };
  },
  [getPartnersSuccess]: (state, { payload: { partners } }) => {
    return { ...state, partners, partnersing: false };
  },

  [getStaffWithDepartmentRequest]: (state) => {
    return { ...state, fetchingStaffWithDepartment: true };
  },
  [getStaffWithDepartmentSuccess]: (state, { payload: { staffs } }) => {
    return { ...state, fetchingStaffWithDepartment: false, staffWithDepartments: staffs };
  },

  [getDepartmentsRequest]: (state) => {
    return { ...state, departmentsing: true };
  },
  [getDepartmentsSuccess]: (state, { payload: { departments } }) => {
    return { ...state, departmentsing: false, departments };
  },

  [getCustomerFullInfoRequest]: (state) => {
    return { ...state, fetchingCustomerFullInfo: true };
  },
  [getCustomerFullInfoSuccess]: (state, { payload: { info } }) => {
    return { ...state, fetchingCustomerFullInfo: false, customerFullInfo: info };
  },

  // Get Family Relationship
  [getFamilyRelationshipRequest]: (state) => {
    return { ...state, fetchingRelationship: true };
  },
  [getFamilyRelationshipSuccess]: (state, { payload: { relationships } }) => {
    return { ...state, fetchingRelationship: false, relationships: relationships };
  },

  // Get loại chứng chỉ
  [getCertificateTypeRequest]: (state) => {
    return { ...state, fetchingCertificateType: true };
  },
  [getCertificateTypeSuccess]: (state, { payload: { certificateTypes } }) => {
    return { ...state, fetchingCertificateType: false, certificateTypes: certificateTypes };
  },

  // Get dân tộc
  [getPeoplesRequest]: (state) => {
    return { ...state, fetchingPeoples: true };
  },
  [getPeoplesSuccess]: (state, { payload: { peoples } }) => {
    return { ...state, fetchingPeoples: false, peoples: peoples };
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    customering: false,
  }),
}, initialState);

export default reducer;
