import { createAction, handleActions } from 'redux-actions';
// - Actions

export const fetchDocumentRequest = createAction('FETCH_DOCUMENT_REQUEST');
export const fetchDocumentSuccess = createAction('FETCH_DOCUMENT_SUCCESS');

export const fetchDocumentsRequest = createAction('FETCH_DOCUMENTS_REQUEST');
export const fetchDocumentsSuccess = createAction('FETCH_DOCUMENTS_SUCCESS');

export const primaryDocumentsRequest = createAction('PRIMARY_DOCUMENT_REQUEST');
export const deleteDocumentsRequest = createAction('DELETE_DOCUMENT_REQUEST');

export const documentDownloadRequest = createAction('DOCUMENT_DOWNLOAD_REQUEST');

export const fetchDocumentHistoryRequest = createAction('FETCH_DOCUMENT_HISTORY_REQUEST');
export const fetchDocumentHistorySuccess = createAction('FETCH_DOCUMENT_HISTORY_SUCCESS');

export const fetchCategoriesRequest = createAction('FETCH_CATEGORIES_REQUEST');
export const fetchCategoriesSuccess = createAction('FETCH_CATEGORIES_SUCCESS');

export const failure = createAction('DOCUMENT_ACTION_FAILURE');


// - Initial State
export const initialState = {
  documents: [],
  pagination: {
    Offset: 1,
    Size: 50,
    Total: 0
  },
  documentsing: false,
  categories:[],
  categoriesing:false,
  document:[],
  documenting:false,
  error: null,
  historydocuments:[],
  historydocumentsing:false
};

// - Reducers
const reducer = handleActions({
  [fetchDocumentsRequest]: (state) => {
    return { ...state, documents: [], documentsing: true };
  },
  [fetchDocumentsSuccess]: (state, { payload: { documents,pagination } }) => {
    return { ...state, documents: documents,pagination: pagination, documentsing: false };
  },
  [fetchDocumentRequest]: (state) => {
    return { ...state, document: [], documenting: true };
  },
  [fetchDocumentSuccess]: (state, { payload: { document } }) => {
    return { ...state, document: document,  documenting: false };
  },

  [fetchDocumentHistoryRequest]: (state) => {
    return { ...state, historydocuments: [], historydocumentsing: true };
  },
  [fetchDocumentHistorySuccess]: (state, { payload: { historydocuments } }) => {
    return { ...state, historydocuments: historydocuments, historydocumentsing: false };
  },
  [fetchCategoriesRequest]: (state) => {
    return { ...state, categories: [], categoriesing: true };
  },
  [fetchCategoriesSuccess]: (state, { payload: { categories,pagination } }) => {
    return { ...state, categories: categories,pagination: pagination, categoriesing: false };
  },
  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    documentsing: false,
  }),
}, initialState);

export default reducer;
