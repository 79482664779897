import { call, put, takeLatest } from 'redux-saga/effects';
import * as UtilityService from '../../services/utility-service';

import {
  getCountriesSuccess,
  getProvincesSuccess,
  getDistrictsSuccess,
  getWardsSuccess,
  getHomeProvincesSuccess,
  getHomeDistrictsSuccess,
  getHomeWardsSuccess,
  getShipProvincesSuccess,
  getShipDistrictsSuccess,
  getShipWardsSuccess,
  findLocationsSuccess,
  searchCustomerSuccess,
  getTypePhonesSuccess,
  failure
} from './reducer';


export function* fetchCountries() {
  try {
    const { Result } = yield call(UtilityService.getCountry);
    if(Result && Result.length) {
      yield put(getCountriesSuccess({ countries: Result }));
    } else {
      yield put(getCountriesSuccess([]));
    }

  } catch (error) {
    yield put(failure(error));
  }
}

export function* checkinCountries() {
  try {
    const { Result } = yield call(UtilityService.getCountryCheckin);
    if(Result && Result.length) {
      yield put(getCountriesSuccess({ countries: Result }));
    } else {
      yield put(getCountriesSuccess([]));
    }

  } catch (error) {
    yield put(getCountriesSuccess([]));
  }
}

export function* fetchProvinces({payload}) {
  try {
    const { Result } = yield call(UtilityService.getProvinces, payload);
    if(Result && Result.length) {
      yield put(getProvincesSuccess(Result));
    } else {
      yield put(getProvincesSuccess([]));
    }

  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchDistricts({payload}) {
  try {
    const { Result } = yield call(UtilityService.getDistricts, payload);
    if(Result && Result.length) {
      yield put(getDistrictsSuccess(Result));
    } else {
      yield put(getDistrictsSuccess([]));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchWards({payload}) {
  try {
    const { Result } = yield call(UtilityService.getWards, payload);
    if(Result && Result.length) {
      yield put(getWardsSuccess(Result));
    } else {
      yield put(getWardsSuccess([]));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchHomeProvinces({payload}) {
  try {
    const { Result } = yield call(UtilityService.getProvinces, payload);
    if(Result && Result.length) {
      yield put(getHomeProvincesSuccess(Result));
    } else {
      yield put(getHomeProvincesSuccess([]));
    }

  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchHomeDistricts({payload}) {
  try {
    const { Result } = yield call(UtilityService.getDistricts, payload);
    if(Result && Result.length) {
      yield put(getHomeDistrictsSuccess(Result));
    } else {
      yield put(getHomeDistrictsSuccess([]));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchHomeWards({payload}) {
  try {
    const { Result } = yield call(UtilityService.getWards, payload);
    if(Result && Result.length) {
      yield put(getHomeWardsSuccess(Result));
    } else {
      yield put(getHomeWardsSuccess([]));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchShipProvinces({payload}) {
  try {
    const { Result } = yield call(UtilityService.getProvinces, payload);
    if(Result && Result.length) {
      yield put(getShipProvincesSuccess(Result));
    } else {
      yield put(getShipProvincesSuccess([]));
    }

  } catch (error) {
    yield put(getShipProvincesSuccess([]));
    yield put(failure(error));
  }
}

export function* fetchShipDistricts({payload}) {
  try {
    const { Result } = yield call(UtilityService.getDistricts, payload);
    if(Result && Result.length) {
      yield put(getShipDistrictsSuccess(Result));
    } else {
      yield put(getShipDistrictsSuccess([]));
    }
  } catch (error) {
    yield put(getShipDistrictsSuccess([]));
    yield put(failure(error));
  }
}

export function* fetchShipWards({payload}) {
  try {
    const { Result } = yield call(UtilityService.getWards, payload);
    if(Result && Result.length) {
      yield put(getShipWardsSuccess(Result));
    } else {
      yield put(getShipWardsSuccess([]));
    }
  } catch (error) {
    yield put(getShipWardsSuccess([]));
    yield put(failure(error));
  }
}

export function* searchLocations({payload}) {
  try {
    const { Result } = yield call(UtilityService.findLocation, payload);
    if(Result && Result.length) {
      yield put(findLocationsSuccess({locations: Result }));
    } else {
      yield put(findLocationsSuccess({locations: []}));
    }
  } catch (error) {
    yield put(findLocationsSuccess({locations: []}));
    yield put(failure(error));
  }
}


export function* searchCustomers({payload}) {
  try {
    const { Result } = yield call(UtilityService.searchCustomers, payload);
    if(Result && Result.length) {
      yield put(searchCustomerSuccess({customers: Result }));
    } else {
      yield put(searchCustomerSuccess({customers : []}));
    }
  } catch (error) {
    yield put(searchCustomerSuccess({customers : []}));
    yield put(failure(error));
  }
}

export function* getTypePhones({ payload = {} }) {
  try {
    const { Result } = yield call(UtilityService.getTypePhones, payload);
    if(Result) {
      yield put(getTypePhonesSuccess({ phones: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}


export default [
  takeLatest('GET_COUNTRY_REQUEST', fetchCountries),
  takeLatest('CHECKIN_COUNTRY_REQUEST', checkinCountries),
  takeLatest('GET_PROVINCES_REQUEST', fetchProvinces),
  takeLatest('GET_DISTRICTS_REQUEST', fetchDistricts),
  takeLatest('GET_WARDS_REQUEST', fetchWards),

  takeLatest('GET_HOME_PROVINCES_REQUEST', fetchHomeProvinces),
  takeLatest('GET_HOME_DISTRICTS_REQUEST', fetchHomeDistricts),
  takeLatest('GET_HOME_WARDS_REQUEST', fetchHomeWards),

  takeLatest('GET_SHIPPER_PROVINCES_REQUEST', fetchShipProvinces),
  takeLatest('GET_SHIPPER_DISTRICTS_REQUEST', fetchShipDistricts),
  takeLatest('GET_SHIPPER_WARDS_REQUEST', fetchShipWards),
  takeLatest('FIND_LOCATIONS_REQUEST', searchLocations),

  takeLatest('SEARCH_CUSTOMERS_REQUEST', searchCustomers),

  takeLatest('GET_TYPE_PHONE_REQUEST', getTypePhones),
];
