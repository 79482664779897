import { call, put, takeLatest } from "redux-saga/effects";
import * as userService from "../../services/user-service";
import Auth from '../../storage/Auth';
import { Modal } from 'antd';
import moment from 'moment';
import { UserFormAction, PassFormAction, AddressFormAction } from "./action";
import { SubmissionError } from "redux-form";

import {
  searchUsersSuccess,
  getUserSuccess,
  getAvatarSuccess,
  loadAddressSuccess,
  searchStaffSuccess,
  searchMyStaffSuccess,
  searchStationStaffSuccess,
  getStaffByCodeSuccess,
  getNotificationsSuccess,
  failure
} from "./reducer";

const notifyUser = () => {
  Modal.error({
    title: 'Lỗi tài khoản!',
    content: 'Đã có lỗi xảy ra khi lấy thông tin tài khoản. Vui lòng liên hệ PCSPOST 1900 599 838 để được hỗ trợ!',
    okCancel: false,
    okText: 'Thoát',
    onOk() {
      Auth.deauthenticateUser();
      window.location.href = "/account/dang-nhap";
    },
    onCancel() {
    }
  });
}

export function* fetchUsers({ payload = {} }) {
  try {
    const { data } = yield call(userService.searchUser, payload);
    if (data) {
      const { results, pagination } = data;
      yield put(searchUsersSuccess({ users: results, pagination }));
    } else {
      yield put(failure("Search users failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getUser() {
  try {
    const user = Auth.getUser();
    yield put(getUserSuccess({ user: user }));
    // const { Result } = yield call(userService.getUserById);
    // if (Result && Result.AccountNr) {
    //   yield put(getUserSuccess({ user: Result }));
    // } else {
    //   yield put(getUserSuccess({ user: null }));
    //   // notifyUser();
    // }
  } catch (error) {
    yield put(getUserSuccess({ user: null }));
    if(!(error && error.Message === 'UNAUTHORIZED')) {
      notifyUser();
    }
  }
}

export function* getAvatar({ payload = {} }) {
  try {
    const { Result } = yield call(userService.getAvatarById, payload.AccountNr);
    if (Result) {
      yield put(getAvatarSuccess({ avatar: Result }));
    } else {
      yield put(failure("Get avatar failed"));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* saveUser({ payload }) {
  try {
    const Profile = {
      Name: payload.Name,
      ShortName: payload.ShortName,
      Gender: payload.Gender || false,
      DOB: payload.DOB ? moment(payload.DOB, 'DD-MM-YYYY').format() : '',
      Remark: payload.Remark,
    }

    const result = yield call(userService.updateUser, { Profile });
    if (result) {
      yield put(UserFormAction.success("Cập nhật thành công"));
    } else {
      const formError = new SubmissionError({
        _error: 'Lưu thất bại!'
      });
      yield put(UserFormAction.failure(formError));
    }
  } catch (error) {
    let messsage = 'Lưu thất bại'
    if(error && error.Description && typeof error.Description === 'string') {
      messsage = error.Description;
    } else if (error && error.Message && typeof error.Message === 'string') {
      messsage = error.Message;
    }
    const formError = new SubmissionError({
      _error: messsage
    });
    yield put(UserFormAction.failure(formError));
  }
}

export function* changePassword({ payload }) {
  try {
    const result= yield call(userService.changePassword, payload);
    if (result) {
      yield put(PassFormAction.success("Thay đổi thành công"));
    } else {
      const message  = "Thay đổi không thành công!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(PassFormAction.failure(formError));
    }
  } catch (error) {
    let messsage = 'Thay đổi không thành công!'
    if(error && error.Description && typeof error.Description === 'string') {
      messsage = error.Description;
    } else if (error && error.Message && typeof error.Message === 'string') {
      messsage = error.Message;
    }
    const formError = new SubmissionError({
      _error: messsage
    });
    yield put(PassFormAction.failure(formError));
  }
}

/**address */
export function* loadAddress() {
  try {
    const { Result } = yield call(userService.loadAddress);
    if (Result) {
      yield put(loadAddressSuccess({ address: Result }));
    } else {
      yield put(loadAddressSuccess({ address: [] }));
    }
  } catch (error) {
    yield put(loadAddressSuccess({ address: [] }));
  }
}

export function* saveAddress({ payload }) {
  try {
      let result;
      const address = {
        Street: payload.Street,
        Remark: payload.Remark,
        ProvinceId: payload.ProvinceId,
        DistrictId: payload.DistrictId,
        WardId: payload.WardId,
        IsPrimary: payload.IsPrimary
      }
      const AddressId = payload.AddressId;

      if (AddressId) {
        result = yield call(userService.updateAddress, { address, AddressId });
      } else {
        result = yield call(userService.addAddress, { address });
      }
    
    if (result) {
      yield put(AddressFormAction.success('Lưu thành công'))
    } else {
      const formError = new SubmissionError({
        _error: 'Lưu thất bại!'
      })
      yield put(AddressFormAction.failure(formError))
    }
  } catch (error) {
    let messsage = 'Lưu thất bại!'
    if(error && error.Description && typeof error.Description === 'string') {
      messsage = error.Description;
    } else if (error && error.Message && typeof error.Message === 'string') {
      messsage = error.Message;
    }
    const formError = new SubmissionError({
      _error: messsage
    });
    yield put(AddressFormAction.failure(formError))
  }
}

export function* deleteAddress({ payload }) {
  const { AddressId, cb } = payload;
  try {
    const result = yield call(userService.deleteAddress, AddressId);
    if (result) {
      if(cb) cb('success');
    } else{
      if(cb) cb('error');
    }
  } catch (error) {
    yield put(failure(error));
    if(cb) cb('error');
  }
}


export function* deleteAvatar({ payload }) {
  const { cb } = payload;
  try {
    const result = yield call(userService.deleteAvatar);
    if (result) {
      if(cb) cb('success');
    } else{
      if(cb) cb('error');
    }
  } catch (error) {
    yield put(failure(error));
    if(cb) cb('error');
  }
}

export function* searchStaffs({ payload = {} }) {
  try {
    const { Result } = yield call(userService.searchStaffs, payload);
    if(Result) {
      yield put(searchStaffSuccess({ staffs: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* searchMyStaffs({ payload = {} }) {
  try {
    const { Result } = yield call(userService.searchMyStaffs, payload);
    if(Result) {
      yield put(searchMyStaffSuccess({ staffs: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* searcStationStaffs({ payload = {} }) {
  try {
    const { Result } = yield call(userService.searchStationStaffs, payload);
    if(Result) {
      yield put(searchStationStaffSuccess({ staffs: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getStaffsCode({ payload = {} }) {
  try {
    const { Result } = yield call(userService.getStaffsByCode, payload);
    if(Result) {
      yield put(getStaffByCodeSuccess({ staffs: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getNotifications({ payload = {} }) {
  try {
    const { Result } = yield call(userService.getNotificationsByCode, payload);
    yield put(getNotificationsSuccess({ schedules: Result }));
  } catch (error) {
    yield put(getNotificationsSuccess({ schedules: null }));
  }
}

export function* saveNotification({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result, Message } = yield call(userService.saveNotification, data);
    if (Result) {
      if (cb) cb("success");
    } else {
      if (cb) cb("error", Message);
    }
  } catch (error) {
    if (cb) cb("error");
  }
}

export default [
  takeLatest(UserFormAction.REQUEST, saveUser),
  takeLatest(PassFormAction.REQUEST, changePassword),
  takeLatest("USER_LIST_SEARCH_REQUEST", fetchUsers),
  takeLatest("GET_USER_REQUEST", getUser),
  takeLatest("GET_AVATAR_REQUEST", getAvatar),
  takeLatest('AVATAR_DELETE_REQUEST', deleteAvatar),

  takeLatest(AddressFormAction.REQUEST, saveAddress),
  takeLatest("LOAD_ADDRESS_REQUEST", loadAddress),
  takeLatest('ADDRESS_DELETE_REQUEST', deleteAddress),

  takeLatest('SEARCH_STAFF_REQUEST', searchStaffs),
  takeLatest('SEARCH_MY_STAFF_REQUEST', searchMyStaffs),
  takeLatest('SEARCH_STATION_STAFF_REQUEST', searcStationStaffs),
  takeLatest('GET_STAFF_BY_CODE_REQUEST', getStaffsCode),

  takeLatest('GET_SCHEDULES_REQUEST', getNotifications),
  takeLatest('SAVE_SCHEDULE_REQUEST', saveNotification),
  
];
