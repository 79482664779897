import Loadable from 'react-loadable';
import Loading from '../components/Loading';

const LoginPage                = Loadable({loader: () => import(/*webpackChunkName:'login'*/'../auth/login'), loading: Loading});
const CallbackPage             = Loadable({loader: () => import(/*webpackChunkName:'callback'*/'../auth/callback'), loading: Loading});

const MainPage                 = Loadable({loader: () => import(/*webpackChunkName:'mainpages'*/'../pages'), loading: Loading});
const DashboardPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/dashboard/DashboardVM'), loading: Loading});
const SchedulePage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/task/calender/CalenderVM'), loading: Loading});
const TaskPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/task/tasks/TasksVM'), loading: Loading});
const DocumentsPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/documents/DocumentsVM'), loading: Loading});
const DetailDocument           = Loadable({loader: () => import(/*webpackChunkName:'detaildocument'*/'../pages/documents/details/DetailDocumentVM'), loading: Loading});

const LeavePage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/leave/register/LeaveVM'), loading: Loading});
const MyLeavePage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/leave/lists/LeavesVM'), loading: Loading});
const LeavesPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/leaves/LeavesVM'), loading: Loading});
const SupplementPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/supplement/register/SupplementVM'), loading: Loading});
const SupplementsPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/supplement/lists/SupplementsVM'), loading: Loading});
const TimekeepsPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/timekeep/TimekeepVM'), loading: Loading});
const FinesPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/fines/FinesVM'), loading: Loading});
const ProfilePage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/profile/ProfileVM'), loading: Loading});
const ProfilesPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/profile/ProfilesVM'), loading: Loading});
const AssetPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/asset/AssetVM'), loading: Loading});
const RequestPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/request/RequestVM'), loading: Loading});
const BonusPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/bonus/BonusVM'), loading: Loading});

const ContactPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/contact/ContactVM'), loading: Loading});

const AssetsPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/assets/AssetsVM'), loading: Loading});
const AssetTypesPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/assets/TypesVM'), loading: Loading});
const AssetRepairsPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/assets/RepairsVM'), loading: Loading});
const AssetRequestsPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/assets/RequestsVM'), loading: Loading});
const AssetReportsPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/assets/ReportsVM'), loading: Loading});
const AssetImportsPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/assets/ImportVM'), loading: Loading});

const HumansPage            = Loadable({loader: () => import(/*webpackChunkName:'dashboards'*/'../pages/humans/HumansVM'), loading: Loading});

const routes = [
  { exact: true, path: "/login", component: LoginPage },
  { exact: true, path: "/callback", component: CallbackPage },
  {
    exact: false,
    path: "/",
    component: MainPage,
    private: true,
    routes: [
      { exact: true, path: "/", component: DashboardPage },
      { exact: true, path: "/timekeep", component: TimekeepsPage },
      { exact: true, path: "/fines", component: FinesPage },
      { exact: true, path: "/profile", component: ProfilePage },
      { exact: true, path: "/profiles", component: ProfilesPage },
      { exact: true, path: "/contact", component: ContactPage },
      { exact: true, path: "/asset", component: AssetPage },
      { exact: true, path: "/bonus", component: BonusPage },
      { exact: true, path: "/leaves", component: LeavesPage },
      { exact: true, path: "/request", component: RequestPage },
      { exact: true, path: "/schedule", component: SchedulePage },
      { exact: true, path: "/task", component: TaskPage },
      { exact: true, path: "/document", component: DocumentsPage },
      { exact: true, path: "/document/:id", component: DocumentsPage },
      { exact: true, path: "/detail-document/:id", component: DetailDocument },
      

      { exact: true, path: "/assets", component: AssetsPage },
      { exact: true, path: "/assets/types", component: AssetTypesPage },
      { exact: true, path: "/assets/repairs", component: AssetRepairsPage },
      { exact: true, path: "/assets/requests", component: AssetRequestsPage },
      { exact: true, path: "/assets/reports", component: AssetReportsPage },
      { exact: true, path: "/assets/imports", component: AssetImportsPage },

      { exact: true, path: "/leave", component: LeavePage },
      { exact: true, path: "/leave/me/all", component: MyLeavePage },
      { exact: true, path: "/leave/me/pending", component: MyLeavePage },
      { exact: true, path: "/leave/me/accept", component: MyLeavePage },
      { exact: true, path: "/leave/me/reject", component: MyLeavePage },
      { exact: true, path: "/leave/related/all", component: MyLeavePage },
      { exact: true, path: "/leave/related/pending", component: MyLeavePage },
      { exact: true, path: "/leave/related/accept", component: MyLeavePage },
      { exact: true, path: "/leave/related/reject", component: MyLeavePage },
      { exact: true, path: "/leave/censor/all", component: MyLeavePage },
      { exact: true, path: "/leave/censor/pending", component: MyLeavePage },
      { exact: true, path: "/leave/censor/accept", component: MyLeavePage },
      { exact: true, path: "/leave/censor/reject", component: MyLeavePage },

      { exact: true, path: "/supplement", component: SupplementPage },
      { exact: true, path: "/supplement/me/:id", component: SupplementsPage },
      { exact: true, path: "/supplement/related/:id", component: SupplementsPage },

      { exact: true, path: "/hr", component: HumansPage },
    ]
  }
];

export default routes
