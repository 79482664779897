import React from 'react'
import ReactDOM from 'react-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import App from './app'
import './styles/styles.less'
import './styles/styles.scss'

import store from './reduxs/store';
import history from './utils/history';
import { OidcProvider } from 'redux-oidc';
import userManager from './utils/userManager';

import { ConfigProvider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import viVN from 'antd/es/locale/vi_VN';
import moment from 'moment';
import 'moment/locale/vi';
import './extentions'

moment.locale('vi-VN');
Spin.setDefaultIndicator(<div style={{width: '80px'}}>
                          <LoadingOutlined style={{ fontSize: 30 }} spin />
                          <p style={{fontSize: '13px'}} className="text-center">Đang tải...</p>
                        </div>)

ReactDOM.render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <ConnectedRouter history={history}>
        <ConfigProvider locale={viVN}>
          <App />
        </ConfigProvider>
      </ConnectedRouter>
    </OidcProvider>
  </Provider>,
  document.getElementById('root')
);
