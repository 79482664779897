import { all } from "redux-saga/effects";
import authSaga from "./auth-redux/saga";
import userSaga from "./user-redux/saga";
import utilitySaga from './utility-redux/saga';
import commonSaga from './common-redux/saga';
import stationSaga from './station-redux/saga';
import statisticsSaga from './statistics-redux/saga';
import assetSaga from './asset-redux/saga';
import absentSaga from './absent-redux/saga';
import profileSaga from './profile-redux/saga';
import documentSaga from './document-redux/saga';

export default function* rootSaga(getState) {
  yield all([
    ...authSaga,
    ...userSaga,
    ...utilitySaga,
    ...commonSaga,
    ...stationSaga,
    ...statisticsSaga,

    ...assetSaga,
    ...absentSaga,
    ...profileSaga,

    ...documentSaga
  ]);
}
