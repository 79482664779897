export const apiEndpoint = ((app_env) => {
  switch (app_env) {
    case 'production': {
      return 'https://apiv2.pcs.vn';
    }
    case 'staging': {
      return 'https://apiv2.pcs.vn';
    }
    default: {
      return 'https://apiv2.pcs.vn';
    }
  }
})(process.env.REACT_APP_ENV);

export const apiEndpointHR = 'https://api-hrm.pcstech.co'

export const title = 'Hệ thống vận hành - Operating system';
export const ApiUploadImage = `${apiEndpoint}/api/upload/file`;
export const ApiUploadAvatar = `/api/user-info/avatar`;
export const getUrlAvatar = (accounNr) => `${apiEndpoint}/api/user-info/${accounNr}`;
export const auth = {
  appFacebookId: '637698013405871',
  clientIdGoogle: '408369692449-l7jqfvaqe5h0t0qfd8edbfha5ua2hg03.apps.googleusercontent.com'
};

const maxBirthday = () => {
  let maxDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() - 16)
  return maxDate
}

const minBirthday = () => {
  let maxDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() - 120)
  return maxDate
}

export const APP_CONFIG = {
  GOOGLE_MAP_URL: "",
  URL_LOGIN: 'https://id.pcs.vn',

  BIRTHDAY: {
    MIN_BIRTHDAY: minBirthday(),
    MAX_BIRTHDAY: maxBirthday()
  },

  VOL_MODE: [
    {value: '', text: 'Tự động'},
    {value: 6000, text: '6.Express Vol. Weight=L*W*H/6000'},
    {value: 5000, text: '5.Normal Vol. Weight=L*W*H/5000'},
    {value: 3000, text: '3.Normal Vol. Weight=L*W*H/3000'}
  ],

  clientId: 'my-pcs'
}
