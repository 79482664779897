import { createAction, handleActions } from 'redux-actions';
// - Actions
export const assetListRequest = createAction('ASSET_LIST_REQUEST');
export const assetListSuccess = createAction('ASSET_LIST_SUCCESS');
export const assetListCallback = createAction('ASSET_LIST_CALLBACK');
export const excelAssetsRequest = createAction('EXECL_ASSET_REQUEST');

export const assetsUserRequest = createAction('ASSET_USER_LIST_REQUEST');
export const assetsUserSuccess = createAction('ASSET_USER_LIST_SUCCESS');

export const assetTypesRequest = createAction('ASSET_TYPE_LIST_REQUEST');
export const assetTypesSuccess = createAction('ASSET_TYPE_LIST_SUCCESS');

export const statusHistoryRequest = createAction('ASSET_STATUS_HISTORY_REQUEST');
export const statusHistorySuccess = createAction('ASSET_STATUS_HISTORY_SUCCESS');

export const assignHistoryRequest = createAction('ASSET_ASSIGN_HISTORY_REQUEST');
export const assignHistorySuccess = createAction('ASSET_ASSIGN_HISTORY_SUCCESS');

export const repairHistoryRequest = createAction('ASSET_REPAIR_HISTORY_REQUEST');
export const repairHistorySuccess = createAction('ASSET_REPAIR_HISTORY_SUCCESS');

export const reportsHistoryRequest = createAction('ASSET_REPORT_HISTORY_REQUEST');
export const reportsHistorySuccess = createAction('ASSET_REPORT_HISTORY_SUCCESS');

export const requestsHistoryRequest = createAction('ASSET_REQUEST_HISTORY_REQUEST');
export const requestsHistorySuccess = createAction('ASSET_REQUEST_HISTORY_SUCCESS');

export const repairAssetRequest = createAction('ASSET_REPAIR_REQUEST');
export const repairAssetSuccess = createAction('ASSET_REPAIR_SUCCESS');

export const assignStaffRequest = createAction('ASSET_ASSIGN_STAFF_REQUEST');
export const assignStaffSuccess = createAction('ASSET_ASSIGN_STAFF_SUCCESS');

export const assetPrintLabelRequest = createAction('ASSET_PRINT_LABEL_REQUEST');
export const deleteTypesRequest = createAction('ASSET_DEL_TYPE_REQUEST');

export const saveAssetExcelRequest = createAction('SAVE_ASSET_EXCEL_REQUEST');
export const downloadTemplateRequest = createAction('DOWNLOAD_TEMPLATE_EXCEL_REQUEST');


export const failure = createAction('ASSET_ACTION_FAILURE');

// - Initial State
export const initialState = {
  assets: [],
  loading: false,
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  types: [],
  typesing: false,
  
  assetstatus: [],
  assetstatusing: false,

  assigneds: [],
  assignedsing: false,

  repairs: [],
  repairsing: false,
  repairPagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  repair: null,
  repairing: false,

  userassets: [],
  userassetsing: false,
  userPagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  reports: [],
  reportsing: false,
  reportPagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  requests: [],
  requestsing: false,
  requestPagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  assign: null,
  assigning: false,

  error: null,
};

// - Reducers
const reducer = handleActions({
  [assetListRequest]: (state) => ({
    ...state, loading: true,
  }),
  [assetListSuccess]: (state, { payload: { assets, pagination } }) => ({
    ...state, assets: assets, pagination: pagination, loading: false,
  }),
  
  [assetsUserRequest]: (state) => ({
    ...state, userassetsing: true,
  }),
  [assetsUserSuccess]: (state, { payload: { assets, pagination } }) => ({
    ...state, userassets: assets, userPagination: pagination, userassetsing: false,
  }),

  [assetTypesRequest]: (state) => ({
    ...state, typesing: true,
  }),
  [assetTypesSuccess]: (state, { payload: { types } }) => ({
    ...state, types: types, typesing: false,
  }),

  [statusHistoryRequest]: (state) => ({
    ...state, assetstatusing: true,
  }),
  [statusHistorySuccess]: (state, { payload: { assetstatus } }) => ({
    ...state, assetstatus, assetstatusing: false,
  }),

  [assignHistoryRequest]: (state) => ({
    ...state, assignedsing: true,
  }),
  [assignHistorySuccess]: (state, { payload: { assigneds } }) => ({
    ...state, assigneds, assignedsing: false,
  }),

  [repairHistoryRequest]: (state) => ({
    ...state, repairsing: true,
  }),
  [repairHistorySuccess]: (state, { payload: { repairs, pagination } }) => ({
    ...state, repairs, repairPagination: pagination, repairsing: false,
  }),

  [reportsHistoryRequest]: (state) => ({
    ...state, reportsing: true,
  }),
  [reportsHistorySuccess]: (state, { payload: { reports, pagination } }) => ({
    ...state, reports, reportPagination: pagination, reportsing: false,
  }),
  
  [requestsHistoryRequest]: (state) => ({
    ...state, requestsing: true,
  }),
  [requestsHistorySuccess]: (state, { payload: { requests, pagination } }) => ({
    ...state, requests, reportPagination: pagination, requestsing: false,
  }),

  [assignStaffRequest]: (state) => ({
    ...state, assigning: true,
  }),
  [assignStaffSuccess]: (state, { payload: { assign } }) => ({
    ...state, assign, assigning: false,
  }),

  [repairAssetRequest]: (state) => ({
    ...state, repairing: true,
  }),
  [repairAssetSuccess]: (state, { payload: { repair } }) => ({
    ...state, repair, repairing: false,
  }),


  [failure]: (state, action) => ({
    ...state,
    loading: false,
    billsing: false,
    error: action.error,
  }),
}, initialState);

export default reducer;
