import { call, put, takeLatest } from 'redux-saga/effects';
import * as stationService from '../../services/station-service';
import Auth from '../../storage/Auth';

import {
  getStationsSuccess,
  getMyStationSuccess,
  setStationSuccess,
  openStationRequest
} from './reducer';

const currentStation = Auth.getStation();

export function* getStations({ payload = {} }) {
  try {
    payload.iDisplayStart = ((payload.Offset - 1) * payload.Size) || 0;
    payload.iDisplayLength = payload.Size || 100;
    const { Result } = yield call(stationService.getStations, payload);
    if(Result && Result.data) {
      const { data, iTotalRecords } = Result
      yield put(getStationsSuccess({ stations: data, pagination: { Total: iTotalRecords, Size: payload.Size, Offset: payload.Offset } }));
    } else {
      yield put(getStationsSuccess({ stations: [], pagination: { Total: 0, Size: payload.Size, Offset: payload.Offset } }));
    }
  } catch (error) {
    yield put(getStationsSuccess({ stations: [], pagination: { Total: 0, Size: payload.Size, Offset: payload.Offset } }));
  }
}

export function* getMyStations({ payload = {} }) {
  try {
    const { Result } = yield call(stationService.getMyStations, payload);
    if(Result && Result.length) {
      const station = Result.find((station) => station.STATION_CODE === currentStation);
      if(station) {
        yield put(setStationSuccess({ station: station }));
      } else {
        yield put(openStationRequest(true))
      }
      yield put(getMyStationSuccess({ myStations: Result }));
    } else {
      yield put(getMyStationSuccess({ myStations: [] }));
    }
  } catch (error) {
    yield put(getMyStationSuccess({ myStations: [] }));
  }
}

export function* excelStations({ payload = {} }) {
  const { data, cb } = payload;
  try {
    yield call(stationService.excelStations, data);
    if (cb)
      cb('success');
  } catch (error) {
    if (cb)
      cb('error')
  }
}

export default [
  takeLatest('STATION_LIST_REQUEST', getStations),
  takeLatest('MY_STATION_LIST_REQUEST', getMyStations),
  takeLatest('EXCEL_STATIONS_REQUEST', excelStations),
];
