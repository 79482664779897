import moment from 'moment';
moment.locale('en')

const getWeekNumber = (date) => {
  let d = new Date(+date)
  d.setHours(0, 0, 0, 0)
  d.setDate(d.getDate() + 4 - (d.getDay() + 1 || 7))
  let yearStart = new Date(d.getFullYear(), 0, 1)
  let weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
  return weekNo
}

export const TitleWeek = (date) => {
  if (date && moment(date).isValid()) {
    date = moment(date)
    const weekNo = getWeekNumber(date._i)
    const beginningOfWeek = moment().week(weekNo).startOf('week').format('DD/MM/YYYY')
    const endOfWeek = moment().week(weekNo).startOf('week').add(6, 'days').format('DD/MM/YYYY')
    let title = `${beginningOfWeek} - ${endOfWeek}`
    return title
  }
  return ''
}

export const TitleDay = (date) => {
  if (date && moment(date).isValid()) {
    return moment(date).format('DD/MM/YYYY')
  }
  return ''
}

export const StartEndTime = (date, type='day') => {
    if (date && moment(date).isValid()) {
      date = moment(date)
      const startOfWeek = moment(date).startOf(type).format();
      const endOfWeek = moment(date).endOf(type).format();
      return { StartTime: startOfWeek, EndTime: endOfWeek }
    }
    return null
}

export const GroupPlanWeek = (date) => {
  const weebs = [];
  if (date && moment(date).isValid()) {
    date = moment(date);
    let dateStart = moment(date).startOf('month').format();
    let dateEnd = moment(date).endOf('month').format();

    let _date = dateStart;
    let i = 0;
    while (i < 6) {

      let startOfWeek = moment(_date).startOf('week').format();
      let endOfWeek = moment(_date).endOf('week').format();

      if(startOfWeek <= dateEnd) {
        weebs.push({
          StartTime: moment(startOfWeek).isAfter(dateStart) ? startOfWeek : dateStart,
          EndTime: moment(endOfWeek).isBefore(dateEnd) ? endOfWeek : dateEnd,
        })
      }
      _date = moment(_date).add(1, 'weeks');

      i++
    }
    
  }
  return weebs
}

export const ObjEachDate = ({From, To}) => {
  const days = {};
  if (From && To && moment(From).isValid() && moment(To).isValid()) {

    let startDay = moment(From).format();
    let endDay = moment(To).add(1, 'day');
    let _date =  startDay;
    while (_date <= endDay) {
      const keyday = moment(_date).format('DD-MM-YYYY');
      days[keyday] = { name: keyday };
      _date = moment(_date).add(1, 'day').format();
    }
  }
  return days
}

export const NumberCommas = (x) => {
  if(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return 0
}

export const scrollMoveTable = (el) => {
  const element = document.querySelector(el);
  let isDown = false;
  let startX;
  let scrollLeft;
  if (element) {
      element.addEventListener('mousedown', (e) => {
          if (!e.target.closest('.no-srcoll')) {
              isDown = true;
              element.classList.add('active');
              startX = e.pageX - element.offsetLeft;
              scrollLeft = element.scrollLeft;
          }
      });
      element.addEventListener('mouseleave', (e) => {
          isDown = false;
          element.classList.remove('active');
      });
      element.addEventListener('mouseup', (e) => {
          isDown = false;
          element.classList.remove('active');
      });
      element.addEventListener('mousemove', (e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - element.offsetLeft;
          const walk = (x - startX) * 3;
          element.scrollLeft = scrollLeft - walk;
      });

  }
};


export const printFrame = (contents) => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  var hh = today.getHours();
  var _mm = today.getMinutes();
  var ss = today.getSeconds();
  if (dd < 10) {
      dd = '0' + dd;
  }
  if (mm < 10) {
      mm = '0' + mm;
  }
  if (hh < 10) {
      hh = '0' + hh;
  }
  if (_mm < 10) {
      mm = '0' + mm;
  }
  if (ss < 10) {
      ss = '0' + ss;
  }
  today = dd + "-" + mm + "-" + yyyy + "-" + hh + _mm + ss;
  const iframe = document.createElement('iframe')
  iframe.setAttribute('title', 'frame1')
  iframe.setAttribute('id', 'frame1')
  iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
  document.body.appendChild(iframe)
  let pri = iframe.contentWindow
  pri.document.open()
  pri.document.write(contents);
  pri.document.close();
  setTimeout(function() {
    pri.focus()
    pri.print()
  }, 500);
};

export const arrayToObject = (_arr = [], objectKey = 'param') => {
  if(!_arr) {
    return {};
  }
  let res = {};
  _arr.forEach((element, index) => {
    res[`${objectKey}[${index}]`] = element;
  });
  return res;
}

export const MakeRound = (_nr, numberOfDigitsAfterTheDecimalPoint = 3) => {
  if (!_nr || isNaN(_nr)) return _nr;

  if (_nr % 1 !== 0) {
      return _nr.toFixed(numberOfDigitsAfterTheDecimalPoint)
  }

  return _nr;
}