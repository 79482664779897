import { createAction, handleActions, combineActions } from 'redux-actions';

// - Actions
export const checkinCountriesRequest = createAction('CHECKIN_COUNTRY_REQUEST');
export const getCountriesRequest = createAction('GET_COUNTRY_REQUEST');
export const getCountriesSuccess = createAction('GET_COUNTRY_SUCCESS');

export const getProvincesRequest = createAction('GET_PROVINCES_REQUEST');
export const getProvincesSuccess = createAction('GET_PROVINCES_SUCCESS');

export const getDistrictsRequest = createAction('GET_DISTRICTS_REQUEST');
export const getDistrictsSuccess = createAction('GET_DISTRICTS_SUCCESS');

export const getWardsRequest = createAction('GET_WARDS_REQUEST');
export const getWardsSuccess = createAction('GET_WARDS_SUCCESS');

export const getHomeProvincesRequest = createAction('GET_HOME_PROVINCES_REQUEST');
export const getHomeProvincesSuccess = createAction('GET_HOME_PROVINCES_SUCCESS');

export const getHomeDistrictsRequest = createAction('GET_HOME_DISTRICTS_REQUEST');
export const getHomeDistrictsSuccess = createAction('GET_HOME_DISTRICTS_SUCCESS');

export const getHomeWardsRequest = createAction('GET_HOME_WARDS_REQUEST');
export const getHomeWardsSuccess = createAction('GET_HOME_WARDS_SUCCESS');

export const getShipProvincesRequest = createAction('GET_SHIPPER_PROVINCES_REQUEST');
export const getShipProvincesSuccess = createAction('GET_SHIPPER_PROVINCES_SUCCESS');

export const getShipDistrictsRequest = createAction('GET_SHIPPER_DISTRICTS_REQUEST');
export const getShipDistrictsSuccess = createAction('GET_SHIPPER_DISTRICTS_SUCCESS');

export const getShipWardsRequest = createAction('GET_SHIPPER_WARDS_REQUEST');
export const getShipWardsSuccess = createAction('GET_SHIPPER_WARDS_SUCCESS');

export const findLocationsRequest = createAction('FIND_LOCATIONS_REQUEST');
export const findLocationsSuccess = createAction('FIND_LOCATIONS_SUCCESS');

export const searchCustomerRequest = createAction('SEARCH_CUSTOMERS_REQUEST');
export const searchCustomerSuccess = createAction('SEARCH_CUSTOMERS_SUCCESS');

export const getTypePhonesRequest = createAction('GET_TYPE_PHONE_REQUEST');
export const getTypePhonesSuccess = createAction('GET_TYPE_PHONE_SUCCESS');

export const failure = createAction('COMMONS_ACTION_FAILURE');


// - Initial State
export const initialState = {
  countries: [],
  countring: false,
  provinces: [],
  districts: [],
  wards: [],

  homeProvinces: [],
  homeDistricts: [],
  homeWards: [],

  shipProvinces: [],
  shipDistricts: [],
  shipWards: [],

  locations: [],
  locationsing: false,

  customers: [],
  customersing: false,

  error: null,
  completed: false,
};

// - Reducers
const reducer = handleActions({
  [getCountriesRequest]: (state) => {
    return { ...state, countring: true };
  },
  [getCountriesSuccess]: (state,  { payload: {countries} }) => {
    return { ...state, countries: countries, countring: false};
  },

  [combineActions(getProvincesRequest, getDistrictsRequest, getWardsRequest, getShipProvincesRequest, getShipDistrictsRequest, getShipWardsRequest)]: (state) => ({
    ...state, completed: false,
  }),
  [getProvincesSuccess]: (state,  { payload }) => {
    return { ...state, provinces: payload, completed: false };
  },
  [getDistrictsSuccess]: (state,  { payload }) => {
    return { ...state, districts: payload, completed: false };
  },
  [getWardsSuccess]: (state,  { payload }) => {
    return { ...state, wards: payload, completed: false };
  },

  [getHomeProvincesSuccess]: (state,  { payload }) => {
    return { ...state, homeProvinces: payload, completed: false };
  },
  [getHomeDistrictsSuccess]: (state,  { payload }) => {
    return { ...state, homeDistricts: payload, completed: false };
  },
  [getHomeWardsSuccess]: (state,  { payload }) => {
    return { ...state, homeWards: payload, completed: false };
  },

  [getShipProvincesSuccess]: (state,  { payload }) => {
    return { ...state, shipProvinces: payload, completed: false };
  },
  [getShipDistrictsSuccess]: (state,  { payload }) => {
    return { ...state, shipDistricts: payload, completed: false };
  },
  [getShipWardsSuccess]: (state,  { payload }) => {
    return { ...state, shipWards: payload, completed: false };
  },

  [findLocationsRequest]: (state) => {
    return { ...state, locationsing: true };
  },
  [findLocationsSuccess]: (state,  { payload: { locations} }) => {
    return { ...state, locations: locations, locationsing: false };
  },

  [searchCustomerRequest]: (state) => {
    return { ...state, customersing: true };
  },
  [searchCustomerSuccess]: (state,  { payload: { customers } }) => {
    return { ...state, customers: customers, customersing: false };
  },

  [getTypePhonesRequest]: (state) => {
    return { ...state, phonesing: true, };
  },
  [getTypePhonesSuccess]: (state, { payload: { phones } }) => {
    return { ...state, phones: phones, phonesing: false};
  },

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    completed: false,
  }),
}, initialState);

export default reducer;
