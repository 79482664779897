
import axios from '../utils/axiosInstance';
import FileSaver from 'file-saver';

export const getStations = async (params) => {
  try {
    const { data } = await axios.get('/ops-station/list-station', {params});
    return data;
  } catch (error) {
    throw error;
  }
};

export const getMyStations = async (params) => {
  try {
    const { data } = await axios.get('/crm/station/my-station');
    return data;
  } catch (error) {
    throw error;
  }
};


export const excelStations = async (params) => {
  try {
    const response = await axios.get('/ops-station/export-station', { params,  responseType: 'blob' });
    const { data } = response;
    const namefile = response.headers['pcs-filename-suggestion'];
    const fileType = response.headers['content-type'];
    var blob = new Blob([data], {type: fileType});
    FileSaver.saveAs(blob, namefile);
    return true;
  } catch (error) {
    throw error;
  }
}