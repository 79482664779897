import { createAction, handleActions } from 'redux-actions';
import { Pagination } from 'antd';
// - Actions
export const getGeneralByCodeRequest = createAction('GET_GENERAL_BY_CODE_REQUEST');
export const getGeneralByCodeSuccess = createAction('GET_GENERAL_BY_CODE_SUCCESS');

export const getInfoJobByCodeRequest = createAction('GET_INFO_JOB_BY_CODE_REQUEST');
export const getInfoJobByCodeSuccess = createAction('GET_INFO_JOB_BY_CODE_SUCCESS');

export const getContactByCodeRequest = createAction('GET_CONTACT_BY_CODE_REQUEST');
export const getContactByCodeSuccess = createAction('GET_CONTACT_BY_CODE_SUCCESS');

export const getListFamilyByCodeRequest = createAction('GET_LIST_FAMILY_BY_CODE_REQUEST');
export const getListFamilyByCodeSuccess = createAction('GET_LIST_FAMILY_BY_CODE_SUCCESS');

export const getListWorkingByCodeRequest = createAction('GET_LIST_WORKING_BY_CODE_REQUEST');
export const getListWorkingByCodeSuccess = createAction('GET_LIST_WORKING_BY_CODE_SUCCESS');

export const deleteWorkingRequest = createAction('DELETE_WORKING_REQUEST');
export const deleteWorkingSuccess = createAction('DELETE_WORKING_SUCCESS');

export const getListContractByCodeRequest = createAction('GET_LIST_CONTRACT_BY_CODE_REQUEST');
export const getListContractByCodeSuccess = createAction('GET_LIST_CONTRACT_BY_CODE_SUCCESS');

export const deleteFamilyRequest = createAction('DELETE_FAMILY_REQUEST');
export const deleteFamilySuccess = createAction('DELETE_FAMILY_SUCCESS');

export const getPreferenceByCodeRequest = createAction('GET_PREFERENCE_BY_CODE_REQUEST');
export const getPreferenceByCodeSuccess = createAction('GET_PREFERENCE_BY_CODE_SUCCESS');

export const getListCertificateByCodeRequest = createAction('GET_LIST_CERTIFICATE_BY_CODE_REQUEST');
export const getListCertificateByCodeSuccess = createAction('GET_LIST_CERTIFICATE_BY_CODE_SUCCESS');

export const deleteCertificateRequest = createAction('DELETE_CERTIFICATE_REQUEST');
export const deleteCertificateSuccess = createAction('DELETE_CERTIFICATE_SUCCESS');

export const getListAwardByCodeRequest = createAction('GET_LIST_AWARD_BY_CODE_REQUEST');
export const getListAwardByCodeSuccess = createAction('GET_LIST_AWARD_BY_CODE_SUCCESS');

export const getListFileByCodeRequest = createAction('GET_LIST_FILE_BY_CODE_REQUEST');
export const getListFileByCodeSuccess = createAction('GET_LIST_FILE_BY_CODE_SUCCESS');
export const deleteFileRequest = createAction('DELETE_FILE_REQUEST');
export const deleteFileSuccess = createAction('DELETE_FILE_SUCCESS');

export const getListSearchContactRequest = createAction('GET_LIST_SEARCH_CONTACT_REQUEST');
export const getListSearchContactSuccess = createAction('GET_LIST_SEARCH_CONTACT_SUCCESS');

export const getListEmployeeRequest = createAction('GET_LIST_EMPLOYEE_REQUEST');
export const getListEmployeeSuccess = createAction('GET_LIST_EMPLOYEE_SUCCESS');

export const deleteEmployeeProfileRequest = createAction('DELETE_EMPLOYEE_PROFILE_REQUEST');
export const deleteEmployeeProfileSuccess = createAction('DELETE_EMPLOYEE_PROFILE_SUCCESS');

export const getProfileByStaffRequest = createAction('GET_PROFILE_BY_STAFF_REQUEST');
export const getProfileByStaffSuccess = createAction('GET_PROFILE_BY_STAFF_SUCCESS');

export const failure = createAction('PROFILE_ACTION_FAILURE');

// - Initial State
export const initialState = {
  loading: false,
  fetching: false,
  error: null,

  general: null,
  fetchingGeneral: false,

  infoJob: null,
  fetchingInfoJob: false,

  contact: null,
  fetchingContact: false,

  families: [],
  fetchingFamilies: false,
  familiesPagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  workings: [],
  fetchingWorkings: false,
  workingsPagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },
  contracts: [],
  fetchingContracts: false,
  contractsPagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  certificates: [],
  fetchingCertificates: false,
  certificatesPagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  awards: [],
  fetchingAwards: false,
  awardsPagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  files: [],
  fetchingFiles: false,
  filesPagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  searchContacts: [],
  fetchingSearchContacts: false,
  searchContactPagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  listEmployee: [],
  fetchingListEmployee: false,
  listEmployeePagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  profileByStaff: null,
  fetchingProfileByStaff: false,

  preference: null,
  fetchingPreference: false,
};

// - Reducers
const reducer = handleActions({
  [getGeneralByCodeRequest]: (state) => {
    return { ...state, loading: true, fetchingGeneral: true };
  },
  [getGeneralByCodeSuccess]: (state, { payload: { general } }) => {
    return { ...state, loading: false, general: general, fetchingGeneral: false };
  },
  [getInfoJobByCodeRequest]: (state) => {
    return { ...state, loading: true, fetchingInfoJob: true };
  },
  [getInfoJobByCodeSuccess]: (state, { payload: { infoJob } }) => {
    return { ...state, loading: false, infoJob: infoJob, fetchingInfoJob: false };
  },
  [getContactByCodeRequest]: (state) => {
    return { ...state, loading: true, fetchingContact: true };
  },
  [getContactByCodeSuccess]: (state, { payload: { contact } }) => {
    return { ...state, loading: false, contact: contact, fetchingContact: false };
  },
  [getListFamilyByCodeRequest]: (state) => {
    return { ...state, loading: true, fetchingFamilies: true };
  },
  [getListFamilyByCodeSuccess]: (state, { payload: { families, pagination } }) => {
    return { ...state, loading: false, families: families, familiesPagination: pagination, fetchingFamilies: false };
  },
  [getListWorkingByCodeRequest]: (state) => {
    return { ...state, loading: true, fetchingWorkings: true };
  },
  [getListWorkingByCodeSuccess]: (state, { payload: { workings, pagination } }) => {
    return { ...state, loading: false, workings: workings, workingsPagination: pagination, fetchingWorkings: false };
  },
  [getListContractByCodeRequest]: (state) => {
    return { ...state, loading: true, fetchingContracts: true };
  },
  [getListContractByCodeSuccess]: (state, { payload: { contracts, pagination } }) => {
    return { ...state, loading: false, contracts: contracts, contractsPagination: pagination, fetchingContracts: false };
  },

  [getListCertificateByCodeRequest]: (state) => {
    return { ...state, loading: true, fetchingCertificates: true };
  },
  [getListCertificateByCodeSuccess]: (state, { payload: { certificates, pagination } }) => {
    return { ...state, loading: false, certificates: certificates, certificatesPagination: pagination, fetchingCertificates: false };
  },

  [getPreferenceByCodeRequest]: (state) => {
    return { ...state, loading: true, fetchingPreference: true };
  },
  [getPreferenceByCodeSuccess]: (state, { payload: { preference } }) => {
    return { ...state, loading: false, preference: preference, fetchingAwards: false };
  },
  [getListAwardByCodeRequest]: (state) => {
    return { ...state, loading: true, fetchingAwards: true };
  },
  [getListAwardByCodeSuccess]: (state, { payload: { awards, pagination } }) => {
    return { ...state, loading: false, awards: awards, awardsPagination: pagination, fetchingAwards: false };
  },
  [getListFileByCodeRequest]: (state) => {
    return { ...state, loading: true, fetchingFiles: true };
  },
  [getListFileByCodeSuccess]: (state, { payload: { files, pagination } }) => {
    return { ...state, loading: false, files: files, filesPagination: pagination, fetchingFiles: false };
  },
  [getListSearchContactRequest]: (state) => {
    return { ...state, loading: true, fetchingSearchContacts: true };
  },
  [getListSearchContactSuccess]: (state, { payload: { searchContacts, pagination } }) => {
    return { ...state, loading: false, searchContacts: searchContacts, searchContactPagination: pagination, fetchingSearchContacts: false };
  },

  [getListEmployeeRequest]: (state) => {
    return { ...state, loading: true, fetchingListEmployee: true };
  },
  [getListEmployeeSuccess]: (state, { payload: { listEmployee, pagination } }) => {
    return { ...state, loading: false, listEmployee: listEmployee, listEmployeePagination: pagination, fetchingListEmployee: false };
  },

  [getProfileByStaffRequest]: (state) => {
    return { ...state, loading: true, fetchingProfileByStaff: true };
  },
  [getProfileByStaffSuccess]: (state, { payload: { profileByStaff } }) => {
    return { ...state, loading: false, profileByStaff: profileByStaff, fetchingProfileByStaff: false };
  },


  [failure]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })
}, initialState);

export default reducer;
