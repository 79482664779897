import API from '../utils/axiosApiHR';
import moment from 'moment';
import { param } from 'jquery';
// Lấy danh sách loại đơn nghỉ phép
export const fetchAbsentTypes = async (params) => {
  try {
    const { data } = await API.get('/absent/type', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
// Thống kê số ngày nghỉ phép
export const fetchAbsentStatistic = async (params) => {
  try {
    const { data } = await API.get('/absent/statistic', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
// Lấy danh sách đơn xin nghỉ phép
export const fetchAbsentsByStaff = async (params) => {
  try {
    const { data } = await API.get('/absent/list-form-by-user', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

// Đăng ký đơn nghỉ phép
export const saveAbsent = async (params) => {
  try {
    const body = new FormData()
    body.append('StaffCodeApproval', params.StaffCodeApproval)
    body.append('AbsentTypeCode', params.AbsentTypeCode)
    body.append('SalaryPercent', params.SalaryPercent)
    params.RequestDate.map((item, key) => {
      body.append("RequestDate[" + key + "].ABSENT_FORM_START_DATE", item.ABSENT_FORM_START_DATE);
      body.append("RequestDate[" + key + "].ABSENT_FORM_END_DATE", item.ABSENT_FORM_END_DATE);
    })
    params.StaffCodeRelate.map((item, key) => {
      body.append("StaffCodeRelate[" + key + "]", item);
      body.append("StaffCodeRelate[" + key + "]", item);
    })
    body.append('AbsentCurrentTotal', params.AbsentCurrentTotal)
    body.append('AbsentCurrentRemain', params.AbsentCurrentRemain)
    body.append('AbsentCurrentUsed', params.AbsentCurrentUsed)
    body.append('AbsentCurrentPending', params.AbsentCurrentPending)
    body.append('Note', params.Note)
    body.append('Images', params.Images)
    body.append('StatusCode', params.StatusCode)
    body.append('StaffCode', params.StaffCode)
    if (params.ID) {
      body.append('ID', params.ID)
      const { data } = await API.put(`/absent/add-employee-absent-form/${params.ID}`, body);
      return data;
    } else {
      const { data } = await API.post('/absent/add-employee-absent-form', body);
      return data;
    }
  } catch (error) {
    throw error;
  }
};
// Xóa đơn nghỉ phép
export const deleteAbsent = async ({ Id }) => {
  try {
    const { data } = await API.delete(`/absent/add-employee-absent-form/${Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
// Duyệt đơn nghỉ phép
export const approvalEmployeeAbsentForm = async (params = {}) => {
  try {
    const { data } = await API.post(`/absent/approval-employee-absent-form/`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

// Từ chối đơn nghỉ phép
export const rejectEmployeeAbsentForm = async (params = {}) => {
  try {
    const { data } = await API.post(`/absent/reject-employee-absent-form/`, params);
    return data;
  } catch (error) {
    throw error;
  }
};
