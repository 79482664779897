import { combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import { reducer as oidcReducer } from 'redux-oidc';

import authReducer from './auth-redux/reducer';
import userReducer from './user-redux/reducer';
import utilityReducer from './utility-redux/reducer';
import commonReducer from './common-redux/reducer';
import stationReducer from './station-redux/reducer';
import statisticsReducder from './statistics-redux/reducer';

import assetReducder from './asset-redux/reducer';
import absentReducder from './absent-redux/reducer';
import profileReducder from './profile-redux/reducer';

import documentReducder from './document-redux/reducer';

export default (history, injectedReducers) => combineReducers({
    form: formReducer,
    router: connectRouter(history),
    oidc: oidcReducer,
    utility: utilityReducer,
    common: commonReducer,
    station: stationReducer,
    auth: authReducer,
    user: userReducer,
    statistics: statisticsReducder,

    asset: assetReducder,
    absent: absentReducder,
    profile: profileReducder,

    document:documentReducder
  });
