import React, { Fragment } from 'react'
import { lifecycle, compose as recompose } from 'recompose';
import { connect } from 'react-redux';
import { Modal, Checkbox } from 'antd';
const { confirm } = Modal;

const localStorageKey = 'op-app-version';
const localStorageAutoUpdateKey = 'op-app-auto-update-version';

const confirmContent = () => {
    return (<div>
        <div>Phần mềm có bản cập nhật mới. Bạn có đồng ý cập nhật không?</div>
        <br/>
        <div><Checkbox onChange={(evt) => {
            localStorage.setItem(localStorageAutoUpdateKey, evt.target.checked);
        }}>Tự động cập nhật những lần tiếp theo</Checkbox></div>
        </div>)
}

const showConfirmFn = (onOk, onCancel) => {
    confirm({
      title: 'Xác nhận cập nhật phiên bản mới',
      content: confirmContent(),
      onOk() {
        if (onOk) {
          onOk();
        }
      },
      onCancel() {
        if (onCancel) {
          onCancel();
        }
      },
    });
}

const CheckNewVersion = () => {
    return <Fragment/>
}

const mapDispatchToProps = () => {
    return {};
}

const mapStateToProps = () => ({
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default recompose(
    withConnect,
    lifecycle({
        componentDidMount() {
            const { loading, isLatestVersion, refreshCacheAndReload, version, setModalConfirm, modalConfirmShown } = this.props;
            if (loading) return;
            if (!loading && !isLatestVersion) {
                if (!localStorage.getItem(localStorageKey)) {
                    refreshCacheAndReload(version);
                } else {
                    const showConfirm = JSON.parse(localStorage.getItem(localStorageAutoUpdateKey)) !== true;
                    if (showConfirm) {
                    if (!modalConfirmShown) {
                        setModalConfirm(true);
                        showConfirmFn(() => {
                            refreshCacheAndReload(version);
                        });
                    }
                    } else {
                    refreshCacheAndReload(version);
                    }
                }
            }
            return;
        }
    })
  )(CheckNewVersion)