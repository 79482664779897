import axios from "../utils/axiosInstance";

export const searchUser = async params => {
  try {
    const { data } = await axios.get("/api/users", {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUserById = async () => {
  try {
    const { data } = await axios.get("/user/info");
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAvatarById = async (AccountNr) => {
  try {
    const { data } = await axios.get(`/api/user-info/${AccountNr}`);
    return data;
  } catch (error) {
    throw error;
  }
};


export const updateUser = async ({Profile, Phones, Emails}) => {
  try {
    // await Promise.all(
    //   Phones.map((item) => item.Id ? axios.put(`/api/user-info/phone?PhoneId=${item.Id}`, item) : axios.post('/api/user-info/phone', item))
    // );
    // await Promise.all(
    //   Emails.map((item) => item.Id ? axios.put(`/api/user-info/email?EmailId=${item.Id}`, {Email: item.Email, IsPrimary: item.IsPrimary}) : axios.post('/api/user-info/email', item))
    // );
    const { data } = await axios.put(`/api/user-info/basic`, Profile);
    return data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (params) => {
  try {
    const { data } = await axios.post(`https://id.pcs.vn/api/account/change-password`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

/**address */
export const loadAddress = async () => {
  try {
    const { data } = await axios.get(`/api/user-info/address`);
    return data;
  } catch (error) {
    throw error;
  }
};
export const addAddress = async ({address }) => {
  try {
    const { data } = await axios.post(`/api/user-info/address`, address);
    return data;
  } catch (error) {
    throw error;
  }
};
export const updateAddress = async ({address, AddressId}) => {
  try {
    const { data } = await axios.put(`/api/user-info/address?AddressId=${AddressId}`, address);
    return data;
  } catch (error) {
    throw error;
  }
};
export const deleteAddress = async (AddressId) => {
  try {
    const { data } = await axios.delete(`/api/user-info/address?AddressId=${AddressId}`);
    return data;
  } catch (error) {
    throw error;
  }
};


export const deleteAvatar = async () => {
  try {
    const { data } = await axios.delete(`/api/user-info/avatar`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const searchStaffs = async (params ={}) => {
  try {
    const { data } = await axios.get('/crm/staff', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const searchMyStaffs = async (params ={}) => {
  try {
    const { data } = await axios.get('/crm/staff/my-staff', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const searchStationStaffs = async (params ={}) => {
  try {
    const { data } = await axios.get('/ops-staff/list-staff-by', { params });
    return data;
  } catch (error) {
    throw error;
  }
};


export const getStaffsByCode = async (params ={}) => {
  try {
    const { data } = await axios.get('/crm/staff/by-code', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getNotificationsByCode = async () => {
  try {
    const { data } = await axios.get(`/api/user/notification/setting`);
    return data;
  } catch (error) {
    throw error;
  }
};
export const getNotificationTypes = async () => {
  try {
    const { data } = await axios.get('/api/money-schedule/type');
    return data;
  } catch (error) {
    throw error;
  }
};
export const saveNotification = async (body) => {
  try {
    const { data } = await axios.put(`/api/user/notification/setting`, body);
    return data;
  } catch (error) {
    throw error;
  }
};