
import axios from '../utils/axiosInstance';
// import { printFrame } from '../helpers/common';
import FileSaver from 'file-saver';

export const fetchStatisticsPickups = async (params) => {
  try {
    const { data } = await axios.get('/ops-collect-pickup-money/list-collect-pickup-money-statistics', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const excelStatisticsPickups = async (params) => {
  try {
    const response = await axios.get('/ops-collect-pickup-money/export-list-collect-pickup-money-statistics', { params,  responseType: 'blob' });
    const { data } = response;
    const namefile = response.headers['pcs-filename-suggestion'];
    const fileType = response.headers['content-type'];
    var blob = new Blob([data], {type: fileType});
    FileSaver.saveAs(blob, namefile);
    return true;
  } catch (error) {
    throw error;
  }
}


export const fetchStatisticsDeliveries = async (params) => {
  try {
    const { data } = await axios.get('/ops-collecting-statistics/list-delivery-statistics', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const excelStatisticsDeliveries = async (params) => {
  try {
    const response = await axios.get('/ops-collecting-statistics/export-delivery-statistics', { params,  responseType: 'blob' });
    const { data } = response;
    const namefile = response.headers['pcs-filename-suggestion'];
    const fileType = response.headers['content-type'];
    var blob = new Blob([data], {type: fileType});
    FileSaver.saveAs(blob, namefile);
    return true;
  } catch (error) {
    throw error;
  }
}


export const fetchStatisticsQuantities = async (params) => {
  try {
    if(Number(params.Tab) === 1) {
      const { data } = await axios.get('/ops-output-statistics/get-output-statistics', { params });
      return data;
    }
    if(Number(params.Tab) === 2) {
      const { data } = await axios.get('/ops-output-statistics/get-outpustatistics-pickup', { params });
      return data;
    }
    if(Number(params.Tab) === 3) {
      const { data } = await axios.get('/ops-output-statistics/get-outpustatistics-delivery', { params });
      return data;
    }
    if(Number(params.Tab) === 4) {
      const { data } = await axios.get('/ops-output-statistics/get-outpustatistics-cod', { params });
      return data;
    }
    throw new Error();
  } catch (error) {
    throw error;
  }
};

export const excelStatisticsQuantities = async (params) => {
  try {
    const response = await axios.get('/ops-output-statistics/excel-output-statistics', { params,  responseType: 'blob' });
    const { data } = response;
    const namefile = response.headers['pcs-filename-suggestion'];
    const fileType = response.headers['content-type'];
    var blob = new Blob([data], {type: fileType});
    FileSaver.saveAs(blob, namefile);
    return true;
  } catch (error) {
    throw error;
  }
}
