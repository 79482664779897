
import API from '../utils/axiosApiHR';

export const fetchGeneral = async (params) => {
  try {
    const { data } = await API.get('/employee-profile/profile-general', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchInfoJob = async (params) => {
  try {
    const { data } = await API.get('/employee-profile/profile-info-job', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchContact = async (params) => {
  try {
    const { data } = await API.get('/employee-profile/profile-contact', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
export const saveContact = async (params) => {
  try {
    var body = new Object();
    body.StaffCode = params.STAFF_CODE;
    body.Mobile = params.MOBILE;
    body.HomePhone = params.HOME_PHONE;
    body.BussinessPhone = params.BUSSINESS_PHONE;
    body.OtherPhone = params.OTHER_PHONE;
    body.Email = params.EMAIL;
    body.OtherEmail = params.OTHER_EMAIL;
    body.PersonalEmail = params.PERSONAL_EMAIL;
    body.SKYPE = params.SKYPE;
    body.FACEBOOK = params.FACEBOOK;
    body.ZALO = params.ZALO;
    body.HouseCountry = params.HOUSEHOLD_COUNTRY;
    body.HouseProvinceID = params.HOUSEHOLD_PROVINCE_ID;
    body.HouseProvinceName = params.HOUSEHOLD_PROVINCE_NAME;
    body.HouseDistrictID = params.HOUSEHOLD_DISTRICT_ID;
    body.HouseDistrictName = params.HOUSEHOLD_DISTRICT_NAME;
    body.HouseWardID = params.HOUSEHOLD_WARD_ID;
    body.HouseWardName = params.HOUSEHOLD_WARD_NAME;
    body.HouseAddress = params.HOUSEHOLD_ADDRESS;
    body.HomeCountry = params.HOME_COUNTRY;
    body.HomeProvinceID = params.HOME_PROVINCE_ID;
    body.HomeProvinceName = params.HOME_PROVINCE_NAME;
    body.HomeDistrictID = params.HOME_DISTRICT_ID;
    body.HomeDistrictName = params.HOME_DISTRICT_NAME;
    body.HomeWardId = params.HOME_WARD_ID;
    body.HomeWardName = params.HOME_WARD_NAME;
    body.HomeAddress = params.HOME_ADDRESS;
    body.ContactName = params.CONTACT_NAME;
    body.RelationCode = params.CONTACT_RELATION_CODE;
    body.ContactPhone = params.CONTACT_PHONE;
    body.ContactMobile = params.CONTACT_MOBILE;
    body.ContactAddress = params.CONTACT_ADDRESS;
    const { data } = await API.post('/employee-profile/employee-contact', body);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchFamilies = async (params) => {
  try {
    const { data } = await API.get('/employee-profile/profile-family/list', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
export const saveFamily = async (params) => {
  try {
    if (params.ID) {
      const { data } = await API.put('/employee-profile/profile-family/update', params);
      return data;
    } else {
      const { data } = await API.post('/employee-profile/profile-family/insert', params);
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const deleteFamily = async ({ Id }) => {
  try {
    const { data } = await API.delete(`/employee-profile/profile-family/delete/${Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

/// Kinh nghiệm làm việc
export const fetchWorkings = async (params) => {
  try {
    const { data } = await API.get('/employee-profile/profile-working/list', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
export const saveWorking = async (params) => {
  try {
    if (params.ID) {
      const { data } = await API.put('/employee-profile/profile-working/update', params);
      return data;
    } else {
      const { data } = await API.post('/employee-profile/profile-working/insert', params);
      return data;
    }
  } catch (error) {
    throw error;
  }
};
export const deleteWorking = async ({ Id }) => {
  try {
    const { data } = await API.delete(`/employee-profile/profile-working/delete/${Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

/// Hợp đồng
export const fetchContracts = async (params) => {
  try {
    const { data } = await API.get('/employee-profile/list-all-contract', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

/// Chứng chỉ
export const fetchCertificates = async (params) => {
  try {
    const { data } = await API.get('/employee-profile/list-certificate', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
export const saveCertificate = async (params) => {
  try {
    const body = new FormData()

    body.append('TypeCode', params.TypeCode)
    body.append('CertificateLevel', params.CertificateLevel)
    body.append('StartDate', params.StartDate)
    body.append('EndDate', params.EndDate)
    body.append('Signer', params.Signer)
    body.append('Images', params.Image)
    body.append('Note', params.Note)
    body.append('Name', params.Name)
    if (params.ID) {
      body.append('ID', params.ID)
      const { data } = await API.put('/employee-profile/employee-certificate/update/', body);
      return data;
    } else {
      const { data } = await API.post('/employee-profile/employee-certificate/add', body);
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const deleteCertificate = async ({ Id }) => {
  try {
    const { data } = await API.delete(`/employee-profile/employee-certificate/delete/${Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

/// Kỹ năng - Sở thích
export const fetchPreference = async (params) => {
  try {
    const { data } = await API.get('/employee-profile/profile-preferences', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
export const savePreference = async (params) => {
  try {
    const { data } = await API.post('/employee-profile/profile-preferences/update', params);
    return data;
  } catch (error) {
    throw error;
  }
};

/// Khen thưởng
export const fetchAwards = async (params) => {
  try {
    const { data } = await API.get('/employee-profile/list-award-by-employee', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

/// Giấy tờ
export const fetchFiles = async (params) => {
  try {
    const { data } = await API.get('/employee-profile/list-file', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
export const saveFile = async (params) => {
  try {
    const body = new FormData()
    body.append('FILE_NOTE', params.FILE_NOTE)
    body.append('FILE_NAME', params.FILE_NAME)
    body.append('IMAGE', params.IMAGE)
    body.append('FILE_IMAGE', params.FILE_IMAGE)
    body.append('STAFF_CODE', params.StaffCode)
    if (params.ID) {
      body.append('ID', params.ID)
      const { data } = await API.put('/employee-profile/employee-file/update/', body);
      return data;
    } else {
      const { data } = await API.post('/employee-profile/employee-file/add', body);
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const deleteFile = async ({ Id }) => {
  try {
    const { data } = await API.delete(`/employee-profile/employee-file/delete/${Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

/// Danh bạ nhân viên
export const fetchSearchContacts = async (params) => {
  try {
    const { data } = await API.get('/employee-profile/search-contact', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
// Lấy list hồ sơ nhân viên
export const fetchListEmployee = async (params) => {
  try {
    const { data } = await API.get('/employee-profile/get-list-employee-profile', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
// Lưu thông tin hồ sơ
export const saveProfile = async (params) => {
  try {
    if (params.ID) {
      const { data } = await API.put('/employee-profile/profile', params);
      return data;
    } else {
      const { data } = await API.post('/employee-profile/profile', params);
      return data;
    }
  } catch (error) {
    throw error;
  }
};
export const deleteProfile = async ({ Id }) => {
  try {
    const { data } = await API.delete(`/employee-profile/profile/${Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};
// Lấy thông tin hồ sơ theo mã nhân viên
export const fetchProfileByStaff = async (params) => {
  try {
    const { data } = await API.get('/employee-profile/get-employee-profile', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
