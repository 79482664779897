import { call, put, takeLatest } from 'redux-saga/effects';
import * as assetServices from '../../services/asset-service';
import { SubmissionError } from "redux-form";
import {
  AssetFormAction,
  AssetTypeFormAction,
  AllowAssetFormAction,
  StatusAssetFormAction,
  RepairAssetFormAction,
  ReportAssetFormAction,
  ConfirmReportFormAction,
  RequestAssetFormAction,
  ConfirmRequestFormAction,
} from './action';

import {
  assetListSuccess,
  assetsUserSuccess,
  assetTypesSuccess,
  statusHistorySuccess,
  assignHistorySuccess,
  repairHistorySuccess,
  reportsHistorySuccess,
  requestsHistorySuccess,
  repairAssetSuccess,
  assignStaffSuccess
} from './reducer';

export function* fetchAssets({ payload }) {
  try {
    const { Result } = yield call(assetServices.fetchAssets, payload);
    if(Result.Data) {
      const { Data, Pagination } = Result
      yield put(assetListSuccess({ assets: Data, pagination: Pagination }));
    } else {
      yield put(assetListSuccess({ assets: [], pagination: { Page: 1,Size: 50, Total: 0} }));
    }
  } catch (error) {
    yield put(assetListSuccess({ assets: [], pagination: { Page: 1,Size: 50, Total: 0} }));
  }
}

export function* exceAssets({ payload = {} }) {
  const { data, cb } = payload;
  try {
    yield call(assetServices.excelAssets, data);
    if (cb)
      cb('success');
  } catch (error) {
    if (cb)
      cb('error')
  }
}

export function* getAssetsCallback({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(assetServices.fetchAssets, data);
    if(Result.Data) {
      const { Data, Pagination } = Result
      cb({ assets: Data, pagination: Pagination });
    } else {
      cb();
    }
  } catch (error) {
    cb();
  }
}

export function* fetchUserAssets({ payload }) {
  try {
    const { Result } = yield call(assetServices.fetchUserAssets, payload);
    if(Result.length) {
      yield put(assetsUserSuccess({ assets: Result }));
    } else {
      yield put(assetsUserSuccess({ assets: []}));
    }
  } catch (error) {
    yield put(assetsUserSuccess({ assets: [] }));
  }
}

export function* fetchAssetTypes({ payload }) {
  try {
    const { Result } = yield call(assetServices.fetchAssetTypes, payload);
    if(Result && Result.length) {
      yield put(assetTypesSuccess({ types: Result }));
    } else {
      yield put(assetTypesSuccess({ types: []}));
    }
  } catch (error) {
    yield put(assetTypesSuccess({ types: [] }));
  }
}

export function* fetchStatusHistory({ payload }) {
  try {
    const { Result } = yield call(assetServices.fetchStatusHistory, payload);
    if(Result && Result.length) {
      yield put(statusHistorySuccess({ assetstatus: Result }));
    } else {
      yield put(statusHistorySuccess({ assetstatus: []}));
    }
  } catch (error) {
    yield put(statusHistorySuccess({ assetstatus: [] }));
  }
}

export function* fetchAssignHistory({ payload }) {
  try {
    const { Result } = yield call(assetServices.fetchAssignHistory, payload);
    if(Result && Result.length) {
      yield put(assignHistorySuccess({ assigneds: Result }));
    } else {
      yield put(assignHistorySuccess({ assigneds: []}));
    }
  } catch (error) {
    yield put(assignHistorySuccess({ assigneds: [] }));
  }
}

export function* fetchReportHistory({ payload }) {
  try {
    const { Result } = yield call(assetServices.fetchReports, payload);
    if(Result && Result.Data) {
      const { Data, Pagination } = Result
      yield put(reportsHistorySuccess({ reports: Data, pagination: Pagination }));
    } else {
      yield put(reportsHistorySuccess({ reports: [], pagination: { Page: 1,Size: 50, Total: 0} }));
    }
  } catch (error) {
    yield put(reportsHistorySuccess({ reports: [], pagination: { Page: 1,Size: 50, Total: 0} }));
  }
}

export function* fetchRequestHistory({ payload }) {
  try {
    const { Result } = yield call(assetServices.fetchRequests, payload);
    if(Result && Result.Data) {
      const { Data, Pagination } = Result
      yield put(requestsHistorySuccess({ requests: Data, pagination: Pagination }));
    } else {
      yield put(requestsHistorySuccess({ requests: [], pagination: { Page: 1,Size: 50, Total: 0} }));
    }
  } catch (error) {
    yield put(requestsHistorySuccess({ requests: [], pagination: { Page: 1,Size: 50, Total: 0} }));
  }
}

export function* fetchRepairHistory({ payload }) {
  try {
    const { Result } = yield call(assetServices.fetchRepairHistory, payload);
    if(Result.Data) {
      const { Data, Pagination } = Result
      yield put(repairHistorySuccess({ repairs: Data, pagination: Pagination }));
    } else {
      yield put(repairHistorySuccess({ repairs: [], pagination: { Page: 1,Size: 50, Total: 0} }));
    }
  } catch (error) {
    yield put(repairHistorySuccess({ repairs: [], pagination: { Page: 1,Size: 50, Total: 0} }));
  }
}

export function* getRepairLast({ payload }) {
  try {
    const { Result } = yield call(assetServices.getRepairLast, payload);
    if(Result && Result.ID) {
      yield put(repairAssetSuccess({ repair: Result }));
    } else {
      yield put(repairAssetSuccess({ repair: Result }));
    }
  } catch (error) {
    yield put(repairAssetSuccess({ repair: null }));
  }
}

export function* fetchAssetStaff({ payload }) {
  try {
    const { Result } = yield call(assetServices.fetchAssetStaff, payload);
    if(Result) {
      yield put(assignStaffSuccess({ assign: Result }));
    } else {
      yield put(assignStaffSuccess({ assign: null }));
    }
  } catch (error) {
    yield put(assignStaffSuccess({ assign: null }));
  }
}

export function* saveAsset({ payload }) {
  try {
    let { Result } = yield call(assetServices.saveAsset, payload);
    if (Result) {
      yield put(AssetFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Lưu lại tài sản thất bại!'
      });
      yield put(AssetFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Lưu lại loại tài sản thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(AssetFormAction.failure(formError));
  }
}

export function* saveAssetType({ payload }) {
  try {
    let { Result } = yield call(assetServices.saveAssetType, payload);
    if (Result) {
      yield put(AssetTypeFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Lưu lại loại tài sản thất bại!'
      });
      yield put(AssetTypeFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Lưu lại loại tài sản thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(AssetTypeFormAction.failure(formError));
  }
}
export function* deleteAssetType({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(assetServices.deleteAssetType, data);
    if(Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

export function* assignAsset({ payload }) {
  try {
    let { Result } = yield call(assetServices.assignAsset, payload);
    if (Result) {
      yield put(AllowAssetFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Cập nhập thất bại!'
      });
      yield put(AllowAssetFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Cập nhập thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(AllowAssetFormAction.failure(formError));
  }
}

export function* statusAsset({ payload }) {
  try {
    let { Result } = yield call(assetServices.statusAsset, payload);
    if (Result) {
      yield put(StatusAssetFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Cập nhập trạng thái thất bại!'
      });
      yield put(StatusAssetFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Cập nhập thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(StatusAssetFormAction.failure(formError));
  }
}

export function* repairAsset({ payload }) {
  try {
    let { Result } = yield call(assetServices.repairAsset, payload);
    if (Result) {
      yield put(RepairAssetFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Cập nhập trạng thái thất bại!'
      });
      yield put(RepairAssetFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Cập nhập thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(RepairAssetFormAction.failure(formError));
  }
}

export function* reportAsset({ payload }) {
  try {
    let { Result } = yield call(assetServices.reportAsset, payload);
    if (Result) {
      yield put(ReportAssetFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Gửi yêu cầu thất bại!'
      });
      yield put(ReportAssetFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Gửi yêu cầu thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(ReportAssetFormAction.failure(formError));
  }
}

export function* reportConfirm({ payload }) {
  try {
    let { Result } = yield call(assetServices.reportConfirm, payload);
    if (Result) {
      yield put(ConfirmReportFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Xác nhận thất bại!'
      });
      yield put(ConfirmReportFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Xác nhận thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(ConfirmReportFormAction.failure(formError));
  }
}

export function* requestAsset({ payload }) {
  try {
    let { Result } = yield call(assetServices.requestAsset, payload);
    if (Result) {
      yield put(RequestAssetFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Gửi yêu cầu thất bại!'
      });
      yield put(RequestAssetFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Gửi yêu cầu thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(RequestAssetFormAction.failure(formError));
  }
}

export function* confirmRequestAsset({ payload }) {
  try {
    let { Result } = yield call(assetServices.requestConfirm, payload);
    if (Result) {
      yield put(ConfirmRequestFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Từ chối yêu cầu thất bại!'
      });
      yield put(ConfirmRequestFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Từ chối yêu cầu thất bại!";
    if(error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({_error: _message});
    yield put(ConfirmRequestFormAction.failure(formError));
  }
}

export function* printLabel({ payload = {} }) {
  const { data, cb } = payload;
  try {
    yield call(assetServices.printLabel, data);
    cb('success')
  } catch (error) {
    cb('error')
  }
}

export function* saveAssetExcel({ payload }) {
  const { data, cb } = payload;
  try {
    const { Result } = yield call(assetServices.saveAssetExcel, data);
    if (Result) {
      if(cb) cb('success', Result);
    } else{
      if(cb) cb('error', null);
    }
  } catch (error) {
    if(cb) cb('error', null);
  }
}

export function* downloadTemplate({ payload = {} }) {
  const { params, cb } = payload;
  try {
    const result = yield call(assetServices.downloadTemplate, params);
    if (result) {
      if(cb) cb('success');
    } else{
      if(cb) cb('error');
    }
  } catch (error) {
    if(cb) cb('error');
  }
}


export default [
  takeLatest('ASSET_LIST_REQUEST', fetchAssets),
  takeLatest('ASSET_LIST_CALLBACK', getAssetsCallback),
  takeLatest('EXECL_ASSET_REQUEST', exceAssets),
  takeLatest('ASSET_USER_LIST_REQUEST', fetchUserAssets),
  takeLatest('ASSET_TYPE_LIST_REQUEST', fetchAssetTypes),
  takeLatest('ASSET_STATUS_HISTORY_REQUEST', fetchStatusHistory),
  takeLatest('ASSET_ASSIGN_HISTORY_REQUEST', fetchAssignHistory),
  takeLatest('ASSET_REPAIR_HISTORY_REQUEST', fetchRepairHistory),
  takeLatest('ASSET_REPORT_HISTORY_REQUEST', fetchReportHistory),
  takeLatest('ASSET_REQUEST_HISTORY_REQUEST', fetchRequestHistory),
  takeLatest('ASSET_REPAIR_REQUEST', getRepairLast),
  takeLatest('ASSET_ASSIGN_STAFF_REQUEST', fetchAssetStaff),
  takeLatest('ASSET_DEL_TYPE_REQUEST', deleteAssetType),
  takeLatest('ASSET_PRINT_LABEL_REQUEST', printLabel),
  takeLatest('SAVE_ASSET_EXCEL_REQUEST', saveAssetExcel),
  takeLatest('DOWNLOAD_TEMPLATE_EXCEL_REQUEST', downloadTemplate),
  takeLatest(AssetFormAction.REQUEST, saveAsset),
  takeLatest(AssetTypeFormAction.REQUEST, saveAssetType),
  takeLatest(AllowAssetFormAction.REQUEST, assignAsset),
  takeLatest(StatusAssetFormAction.REQUEST, statusAsset),
  takeLatest(RepairAssetFormAction.REQUEST, repairAsset),
  takeLatest(ReportAssetFormAction.REQUEST, reportAsset),
  takeLatest(ConfirmReportFormAction.REQUEST, reportConfirm),
  takeLatest(RequestAssetFormAction.REQUEST, requestAsset),
  takeLatest(ConfirmRequestFormAction.REQUEST, confirmRequestAsset),
];
