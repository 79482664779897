
import axios from '../utils/axiosInstance';
// import { printFrame } from '../helpers/common';
 import FileSaver from 'file-saver';

export const searchDocuments = async (params) => {
  try {
    const { data } = await axios.get('/document/list-document', { params });
    return data;
  } catch (error) {
    throw error;
  }
};


export const fetchDocuments = async (document) => {
  try {
    console.log();
    const { data } = await axios.get(`/document/add-document/${document.Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveDocument = async (document) => {
  try {
    console.log(document);
    var form_data = new FormData();
    if(document && document.ID) {
      if(document.File) {        
          form_data.append("File", document.File[0]);        
      }
      form_data.append('Category', document.Category || '');
      form_data.append('Group', document.Group || '');
      form_data.append('NAME', document.NAME || '');     
      form_data.append('FileVersion',document.FileVersion);
      form_data.append('Desc',document.Desc);
      if(document.Role && document.Role.length) {
        for (let index = 0; index < document.Role.length; index++) {
          form_data.append("Role["+index+"]", document.Role[index]);
        }
      } 
      const { data } = await axios.put(`/document/add-document/${document.Id}`, form_data);
      return data;
    } else {
      if(document.File) {
        
        form_data.append("File", document.File[0]);
      
    }
    form_data.append('Category', document.Category || '');
    form_data.append('Group', document.Group || '');
    form_data.append('NAME', document.NAME || ''); 
    form_data.append('FileVersion',document.FileVersion);
    form_data.append('Desc',document.Desc);
    if(document.Role && document.Role.length) {
      for (let index = 0; index < document.Role.length; index++) {
        form_data.append("Role["+index+"]", document.Role[index]);
      }
    }
      const { data } = await axios.post('/document/add-document', form_data);
      return data;
    }
  } catch (error) {
    throw error;
  }
};

export const deleteDocument = async id => {
  try {
    const { data } = await axios.delete(`/api/users/delete/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const documentDownload = async (params) => {
  try {
    const response = await axios.get('/document/document-download', {
      params: params,
      responseType: 'blob'
    });

    const { data } = response;
    const namefile = response.headers['pcs-filename-suggestion'];
    const fileType = response.headers['pcs-filetype'];

    var blob = new Blob([data], {type: fileType});
    FileSaver.saveAs(blob, namefile);
    return true;
  } catch (error) {
    throw error;
  }
};

export const HistoryDocuments = async (params) => {
  try {
    const { data } = await axios.get(`/document/document-history/${params.Id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchCategory = async (params) => {
  try {
    const { data } = await axios.get('/document/list-category', { params });
    return data;
  } catch (error) {
    throw error;
  }
};
