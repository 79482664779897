import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import formActionSaga from 'redux-form-saga';
import { createLogger } from 'redux-logger';
import createReducer from './reducers';
import rootSaga from "./sagas";


import history from '../utils/history';
import { loadUser } from "redux-oidc";
import userManager from "../utils/userManager";

const loggerMiddleware = createLogger()

const initialState = {};

function configureStore(initialState = {}, history) {
  let composeEnhancers = compose;
  const reduxSagaMonitorOptions = {};

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (process.env.REACT_APP_ENV !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  const bindMiddleware = middleware => {
    if (process.env.REACT_APP_ENV !== 'production') {
      middleware.push(loggerMiddleware)
      return composeEnhancers(applyMiddleware(...middleware))
    }
    return applyMiddleware(...middleware)
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  const store = createStore(
    createReducer(history),
    initialState,
    bindMiddleware([sagaMiddleware, routerMiddleware(history)])
  )
  //Load user
  loadUser(store, userManager);
  // Extensions
  sagaMiddleware.run(rootSaga);
  sagaMiddleware.run(formActionSaga);
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(history, store.injectedReducers));
    });
  }

  return store;
}

export default configureStore(initialState, history);
