import { createFormAction } from 'redux-form-saga'
export const FORM_KEY = 'ASSET_FORM'
export const AssetFormAction = createFormAction(FORM_KEY)

export const TYPE_FORM_KEY = 'ASSET_TYPE_FORM'
export const AssetTypeFormAction = createFormAction(TYPE_FORM_KEY)

export const ALLOW_FORM_KEY = 'ASSET_ALLOW_FORM'
export const AllowAssetFormAction = createFormAction(ALLOW_FORM_KEY)

export const REPAIR_FORM_KEY = 'ASSET_REPAIR_FORM'
export const RepairAssetFormAction = createFormAction(REPAIR_FORM_KEY)

export const REPORT_FORM_KEY = 'ASSET_REPORT_FORM'
export const ReportAssetFormAction = createFormAction(REPORT_FORM_KEY)

export const REQUEST_FORM_KEY = 'ASSET_REQUEST_FORM'
export const RequestAssetFormAction = createFormAction(REQUEST_FORM_KEY)

export const CONFIRM_FORM_KEY = 'ASSET_CONFIRM_REPORT_FORM'
export const ConfirmReportFormAction = createFormAction(CONFIRM_FORM_KEY)

export const REJECT_FORM_KEY = 'ASSET_REJECT_REQUEST_FORM'
export const ConfirmRequestFormAction = createFormAction(REJECT_FORM_KEY)

export const STATUS_FORM_KEY = 'ASSET_STATUS_FORM'
export const StatusAssetFormAction = createFormAction(STATUS_FORM_KEY)