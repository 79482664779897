import { createAction, handleActions } from 'redux-actions';
// - Actions
export const getAbsentTypesRequest = createAction('GET_ABSENT_TYPE_LIST_REQUEST');
export const getAbsentTypesSuccess = createAction('GET_ABSENT_TYPE_LIST_SUCCESS');

export const getAbsentStatisticRequest = createAction('GET_ABSENT_STATISTIC_REQUEST');
export const getAbsentStatisticSuccess = createAction('GET_ABSENT_STATISTIC_SUCCESS');

export const getListAbsentByStaffRequest = createAction('GET_LIST_ABSENT_BY_STAFF_REQUEST');
export const getListAbsentByStaffSuccess = createAction('GET_LIST_ABSENT_BY_STAFF_SUCCESS');
export const deleteAbsentRequest = createAction('DELETE_ABSENT_REQUEST');
export const deleteAbsentSuccess = createAction('DELETE_ABSENT_SUCCESS');
export const approvalEmployeeAbsentFormRequest = createAction('APPROVAL_EMPLOYEE_ABSENT_FORM_REQUEST');
export const approvalEmployeeAbsentFormSuccess = createAction('APPROVAL_EMPLOYEE_ABSENT_FORM_SUCCESS');
export const rejectEmployeeAbsentFormRequest = createAction('REJECT_EMPLOYEE_ABSENT_FORM_REQUEST');
export const rejectEmployeeAbsentFormSuccess = createAction('APPROVAL_EMPLOYEE_ABSENT_FORM_SUCCESS');

// - Initial State
export const initialState = {
  absentTypes: [],
  typesing: false,

  absentStatistic: null,
  fetchingStatistic: false,

  absents: [],
  fetchingAbsents: false,
  absentsPagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },
};

// - Reducers
const reducer = handleActions({
  [getAbsentTypesRequest]: (state) => ({
    ...state, typesing: true,
  }),
  [getAbsentTypesSuccess]: (state, { payload: { absentTypes } }) => ({
    ...state, absentTypes: absentTypes, typesing: false,
  }),
  [getAbsentStatisticRequest]: (state) => {
    return { ...state, loading: true, fetchingStatistic: true };
  },
  [getAbsentStatisticSuccess]: (state, { payload: { absentStatistic } }) => {
    return { ...state, loading: false, absentStatistic: absentStatistic, fetchingStatistic: false };
  },
  [getListAbsentByStaffRequest]: (state) => {
    return { ...state, loading: true, fetchingAbsents: true };
  },
  [getListAbsentByStaffSuccess]: (state, { payload: { absents, pagination } }) => {
    return { ...state, loading: false, absents: absents, absentsPagination: pagination, fetchingAbsents: false };
  },
}, initialState);

export default reducer;
