import { createFormAction } from "redux-form-saga";

export const FORM_KEY = "USER_INFO_FORM";
export const UserFormAction = createFormAction(FORM_KEY);

export const FORM_CHANGE_KEY = "FORM_CHANGE_PASS_FORM";
export const PassFormAction = createFormAction(FORM_CHANGE_KEY);

export const ADDRESS_FORM_KEY = 'ADDRESS_FORM_KEY';
export const AddressFormAction = createFormAction(ADDRESS_FORM_KEY);

export const ACTIVE_ACCOUNT_FORM = "ACTIVE_ACCOUNT_FORM";
export const ActiveFormAction = createFormAction(ACTIVE_ACCOUNT_FORM);

export const PHONE_ACCOUNT_FORM = "PHONE_ACCOUNT_FORM";
export const PhoneFormAction = createFormAction(PHONE_ACCOUNT_FORM);

export const EMAIL_ACCOUNT_FORM = "EMAIL_ACCOUNT_FORM";
export const EmailFormAction = createFormAction(EMAIL_ACCOUNT_FORM);


