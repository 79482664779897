import { createAction, handleActions } from 'redux-actions';
// - Actions
export const getStationsRequest = createAction('STATION_LIST_REQUEST');
export const getStationsSuccess = createAction('STATION_LIST_SUCCESS');

export const getMyStationRequest = createAction('MY_STATION_LIST_REQUEST');
export const getMyStationSuccess = createAction('MY_STATION_LIST_SUCCESS');

export const openStationRequest = createAction('OPEN_STATION_REQUEST');
export const openStationSuccess = createAction('OPEN_STATION_SUCCESS');

export const setStationSuccess = createAction('SET_STATION_SUCCESS');
export const excelStationsRequest = createAction('EXCEL_STATIONS_REQUEST');

export const failure = createAction('STATION_ACTION_FAILURE');


// - Initial State
export const initialState = {
  myStations: [],
  searching: false,
  openStation: false,
  station: null,

  stations: [],
  loading: false,
  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },

  active: false,
  error: null,
};

// - Reducers
const reducer = handleActions({
  [getStationsRequest]: (state) => {
    return { ...state, loading: true };
  },
  [getStationsSuccess]: (state, { payload: { stations, pagination } }) => {
    return { ...state, stations, pagination, loading: false};
  },

  [getMyStationRequest]: (state) => {
    return { ...state,searching: true };
  },
  [getMyStationSuccess]: (state, { payload: { myStations } }) => {
    return { ...state, myStations: myStations, searching: false};
  },


  [openStationRequest]: (state, { payload }) => {
    return { ...state, openStation: !!payload };
  },
  [setStationSuccess]: (state, { payload: { station } }) => {
    return { ...state, station: station, };
  },
  
  [failure]: (state, action) => ({
    ...state,
    error: action.error,
    searching: false,
  }),
}, initialState);

export default reducer;
