import axios from "../utils/axiosInstance";
import moment from "moment";


export const login = async dataLogin => {
  try {
    const { data } = await axios.post("/api/auth/login", dataLogin);
    return data;
  } catch (error) {
    throw error;
  }
};

export const loginSocial = async dataLogin => {
  try {
    const { data } = await axios.post("/api/auth/loginSocial", dataLogin);
    return data;
  } catch (error) {
    throw error;
  }
};

export const preRegister = async dataLogin => {
  try {
    const { data } = await axios.post("/api/auth/register/pre", dataLogin);
    return data;
  } catch (error) {
    throw error;
  }
};

export const register = async dataLogin => {
  try {
    if(dataLogin && dataLogin.birthdate) {
      dataLogin.birthdate = moment(dataLogin.birthdate, 'DD-MM-YYYY');
    } else {
      dataLogin.birthdate = null;
    }
    const { data } = await axios.post("/api/auth/register", dataLogin);
    return data;
  } catch (error) {
    throw error;
  }
};

export const chekToken = async access_token => {
  try {
    const { data } = await axios.get(`/api/auth/confirm_email?token=${access_token}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const forgot = async dataLogin => {
  try {
    const params = {
      email: dataLogin.email,
      lang: 'vn'
    }
    const { data } = await axios.post(`/api/auth/confirm_reset`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async dataLogin => {
  try {
    const { data } = await axios.post(`/api/auth/reset_password`, dataLogin);
    return data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async dataLogin => {
  try {
    const params = {
      passwordOld: dataLogin.passwordOld,
      password: dataLogin.password
    }
    const { data } = await axios.post(`/users/change-password/${dataLogin.id}`, params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSession = async token => {
  try {
    const { data } = await axios.post(`/api/auth/getSession`, {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'authorization': `Bearer ${token}`
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};
