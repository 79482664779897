import { call, put, takeLatest } from 'redux-saga/effects';
import * as CommonService from '../../services/common-service';
import Auth from '../../storage/Auth';

import {
  autoCompleteCustomerSuccess,
  getStationsSuccess,
  getStaffsSuccess,
  getPartnersSuccess,
  getCustomerFullInfoSuccess,
  getStaffWithDepartmentSuccess,
  getDepartmentsSuccess,
  getFamilyRelationshipSuccess,
  getCertificateTypeSuccess,
  getPeoplesSuccess,
  failure,
} from './reducer';

export function* autoCompleteCustomer({ payload }) {
  try {
    if(payload) {
      const { Result } = yield call(CommonService.autoCompleteCustomer, payload);
      if(Result) {
        yield put(autoCompleteCustomerSuccess({ customers: Result.Data || [] }));
      } else {
        yield put(autoCompleteCustomerSuccess({ customers: [] }));
      }
    } else {
      yield put(autoCompleteCustomerSuccess({ customers: [] }));
    }
  } catch (error) {
    yield put(autoCompleteCustomerSuccess({ customers: [] }));
    yield put(failure(error));
  }
}


export function* fetchStations({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.fetchStations, payload);
    if(Result) {
      yield put(getStationsSuccess({ stations: Result }));
    } else {
      yield put(getStationsSuccess({ stations: [] }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchStaffs({ payload = {} }) {
  try {
    const station = Auth.getStation();
    if(!payload.station)  payload.station = station
    const { Result } = yield call(CommonService.fetchMyStaffs, payload);
    if(Result) {
      yield put(getStaffsSuccess({ staffs: Result }));
    } else {
      yield put(getStaffsSuccess({ staffs: [] }));
    }
  } catch (error) {
    yield put(getStaffsSuccess({ staffs: [] }));
    yield put(failure(error));
  }
}

export function* getPartnersRequest({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.getPartners, payload);
    if(Result) {
      yield put(getPartnersSuccess({ partners: Result }));
    } else {
      yield put(getPartnersSuccess({ partners: [] }));
    }
  } catch (error) {
    yield put(getPartnersSuccess({ partners: [] }));
    yield put(failure(error));
  }
}

export function* getCustomerFullInfo({payload = {}}) {
  try {
    const { cb, Id } = payload;
    const { Result } = yield call(CommonService.getCustomerDetail, Id);
    if(Result) {
      yield put(getCustomerFullInfoSuccess({ info: Result }));
    } else {
      yield put(getCustomerFullInfoSuccess({ info: null }));
    }
    if (cb && typeof cb === 'function') {
      cb(Result)
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* getStaffWithDepartment({ payload = {} }) {
  try {
    const station = Auth.getStation();
    if(!payload.StationCode)  payload.StationCode = station
    const { Result } = yield call(CommonService.getInStationStaffByDepartment, payload);
    if(Result) {
      yield put(getStaffWithDepartmentSuccess({ staffs: Result }));
    } else {
      yield put(getStaffWithDepartmentSuccess({ staffs: [] }));
    }
  } catch (error) {
    yield put(getStaffWithDepartmentSuccess({ staffs: [] }));
    yield put(failure(error));
  }
}

export function* getDepartments({ payload = {} }) {
  try {
    payload.iDisplayStart = 0
    payload.iDisplayLength = 100
    const { Result } = yield call(CommonService.getDepartments, payload);
    if(Result && Result.data) {
      yield put(getDepartmentsSuccess({ departments: Result.data }));
    } else {
      yield put(getDepartmentsSuccess({ departments: [] }));
    }
  } catch (error) {
    yield put(getDepartmentsSuccess({ departments: [] }));
  }
}

export function* fetchFamilyRelationship({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.fetchFamilyRelationship, payload);
    if(Result) {
      yield put(getFamilyRelationshipSuccess({ relationships: Result }));
    } else {
      yield put(getFamilyRelationshipSuccess({ relationships: [] }));
    }
  } catch (error) {
    yield put(getFamilyRelationshipSuccess({ relationships: [] }));
  }
}

export function* fetchCertificateType({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.fetchCertificateType, payload);
    if(Result) {
      yield put(getCertificateTypeSuccess({ certificateTypes: Result }));
    } else {
      yield put(getCertificateTypeSuccess({ certificateTypes: [] }));
    }
  } catch (error) {
    yield put(getCertificateTypeSuccess({ certificateTypes: [] }));
  }
}

export function* fetchPeoples({ payload = {} }) {
  try {
    const { Result } = yield call(CommonService.fetchPeoples, payload);
    if(Result) {
      yield put(getPeoplesSuccess({ peoples: Result }));
    } else {
      yield put(getPeoplesSuccess({ peoples: [] }));
    }
  } catch (error) {
    yield put(getPeoplesSuccess({ peoples: [] }));
  }
}

export default [
  takeLatest('AUTO_COMPLETE_CUSTOMER_REQUEST', autoCompleteCustomer),
  takeLatest('GET_STATIONS_REQUEST', fetchStations),
  takeLatest('GET_STAFFS_REQUEST', fetchStaffs),
  takeLatest('GET_PARTNERS_REQUEST', getPartnersRequest),
  takeLatest('GET_CUSTOMER_FULL_INFO_REQUEST', getCustomerFullInfo),
  takeLatest('GET_STAFF_WITH_DEPARTMENT_REQUEST', getStaffWithDepartment),
  takeLatest('GET_DEPARTMENTS_REQUEST', getDepartments),
  takeLatest('GET_FAMILY_RELATIONSHIP_REQUEST', fetchFamilyRelationship),
  takeLatest('GET_CERTIFICATE_TYPE_REQUEST', fetchCertificateType),
  takeLatest('GET_PEOPLES_REQUEST', fetchPeoples),
];
