import { call, put, takeLatest } from 'redux-saga/effects';
import * as DocumentService from '../../services/document-service';
import { DocumentFormAction } from './action';
import { SubmissionError } from 'redux-form';
// import moment from 'moment';

import {
  fetchDocumentHistorySuccess,
  fetchCategoriesSuccess,
  fetchDocumentsSuccess,
  fetchDocumentSuccess,
  failure,
} from './reducer';

export function* searchDocuments({ payload = {} }) {
  try {
    const { Result } = yield call(DocumentService.searchDocuments, payload);
    if(Result) {
      const { Data, Pagination } = Result;
      yield put(fetchDocumentsSuccess({ documents: Data , pagination: Pagination }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}


export function* fetchDocuments({ payload= { } }) {
  try {
    const { Result } = yield call(DocumentService.fetchDocuments, payload);
    if(Result) {
      yield put(fetchDocumentSuccess({ document: Result}));
     
    } else {
      yield put(fetchDocumentSuccess({ document: null }));
    }
  } catch (error) {
    yield put(fetchDocumentSuccess({ document: null }));
    yield put(failure(error));
  }
}


export function* saveDocument({ payload }) {
  try {
    const { Result } = yield call(DocumentService.saveDocument, payload);
    if (Result) {
      yield put(DocumentFormAction.success("Lưu thành công"));
    } else {
      const { message } = "Lưu thất bại!";
      const formError = new SubmissionError({
        _error: message
      });
      yield put(DocumentFormAction.failure(formError));
    }
  } catch (error) {
    let messsage = 'Lưu địa chỉ kho thất bại'
    if(error && error.Description && typeof error.Description === 'string') {
      messsage = error.Description;
    } else if (error && error.Message && typeof error.Message === 'string') {
      messsage = error.Message;
    }
    const formError = new SubmissionError({
      _error: messsage
    });
    yield put(DocumentFormAction.failure(formError));
  }
}


export function* deleteDocument({ payload = {} }) {
  const { data, cb } = payload;
  try {
    yield call(DocumentService.deleteDocument, data);
    if (cb)
      cb('success');
  } catch (error) {
    if (cb)
      cb('error')
  }
}

export function* documentDownload({ payload = {} }) {
  const { params, cb } = payload;
  try {
    const result = yield call(DocumentService.documentDownload, params);
    if (result) {
      if(cb) cb('success');
    } else{
      if(cb) cb('error');
    }
  } catch (error) {
    if(cb) cb('error');
    yield put(failure(error));
  }
}

export function* HistoryDocuments({ payload = {} }) {
  try {
    const { Result } = yield call(DocumentService.HistoryDocuments, payload);
    if(Result) {
     
      yield put(fetchDocumentHistorySuccess({ historydocuments: Result }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}

export function* fetchCategories({ payload = {} }) {
  try {
    const { Result } = yield call(DocumentService.fetchCategory, payload);
    if(Result) {
      const { Data, Pagination } = Result;
      yield put(fetchCategoriesSuccess({ categories: Data , pagination: Pagination }));
    }
  } catch (error) {
    yield put(failure(error));
  }
}


export default [
  takeLatest(DocumentFormAction.REQUEST, saveDocument),
  takeLatest('FETCH_CATEGORIES_REQUEST', fetchCategories),
  takeLatest('FETCH_DOCUMENT_HISTORY_REQUEST', HistoryDocuments),
  takeLatest('DOCUMENT_DOWNLOAD_REQUEST', documentDownload),
  takeLatest('FETCH_DOCUMENT_REQUEST', fetchDocuments),
  takeLatest('FETCH_DOCUMENTS_REQUEST', searchDocuments),
  takeLatest('DELETE_DOCUMENT_REQUEST', deleteDocument),
];
