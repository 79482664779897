import { call, put, takeLatest } from 'redux-saga/effects';
import * as statisticsService from '../../services/statistics-service';

import {
  statisticsPickupsSuccess,
  statisticsDeliverySuccess,
  statisticsQuantitySuccess,
} from './reducer';

export function* fetchPickups({ payload }) {
  try {
    const { Result } = yield call(statisticsService.fetchStatisticsPickups, payload);
    if(Result && Result.Data) {
      const { Data, Pagination } = Result
      yield put(statisticsPickupsSuccess({ pickups: Data, pagination: Pagination }));
    } else {
      yield put(statisticsPickupsSuccess({ pickups: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(statisticsPickupsSuccess({ pickups: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}

export function* excelPickups({ payload = {} }) {
  const { data, cb } = payload;
  try {
    yield call(statisticsService.excelStatisticsPickups, data);
    if (cb)
      cb('success');
  } catch (error) {
    if (cb)
      cb('error')
  }
}


export function* fetchDeliveries({ payload }) {
  try {
    const { Result } = yield call(statisticsService.fetchStatisticsDeliveries, payload);
    if(Result && Result.Data) {
      const { Data, Pagination } = Result
      yield put(statisticsDeliverySuccess({ deliveries: Data, pagination: Pagination }));
    } else {
      yield put(statisticsDeliverySuccess({ deliveries: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(statisticsDeliverySuccess({ deliveries: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}

export function* excelDeliveries({ payload = {} }) {
  const { data, cb } = payload;
  try {
    yield call(statisticsService.excelStatisticsDeliveries, data);
    if (cb)
      cb('success');
  } catch (error) {
    if (cb)
      cb('error')
  }
}

export function* fetchQuantities({ payload }) {
  try {
    const { Result } = yield call(statisticsService.fetchStatisticsQuantities, payload);
    if(Result && Result.Data) {
      const { Data, Pagination } = Result
      yield put(statisticsQuantitySuccess({ quantities: Data, pagination: Pagination }));
    } else {
      yield put(statisticsQuantitySuccess({ quantities: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(statisticsQuantitySuccess({ quantities: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}

export function* excelQuantities({ payload = {} }) {
  const { data, cb } = payload;
  try {
    yield call(statisticsService.excelStatisticsQuantities, data);
    if (cb)
      cb('success');
  } catch (error) {
    if (cb)
      cb('error')
  }
}

export default [
  takeLatest('STATISTIC_PICKUP_REQUEST', fetchPickups),
  takeLatest('EXCEL_STATISTICS_PICKUP_REQUEST', excelPickups),

  takeLatest('STATISTIC_DELIVERY_REQUEST', fetchDeliveries),
  takeLatest('EXCEL_STATISTICS_DELIVERY_REQUEST', excelDeliveries),

  takeLatest('STATISTIC_QUANTITY_REQUEST', fetchQuantities),
  takeLatest('EXCEL_STATISTICS_QUANTITY_REQUEST', excelQuantities),
];
