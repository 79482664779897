
import axios from '../utils/axiosInstance';
import API from '../utils/axiosApiHR';
export const autoCompleteCustomer = async (params = {}) => {
  try {
    const { data } = await axios.get(`/crm/customer/${params.IsIgnoreRole ? 'search-ignore-role' : 'search'}`, { params });
    return data;
  } catch (error) {
    throw error;
  }
};


export const getServiceType = async (params = {}) => {
  try {
    const { data } = await axios.get('/common/service-type', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getExtraServiceType = async (params = {}) => {
  try {
    const { data } = await axios.get('/common/extra-service-type');
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCurrency = async (params = {}) => {
  try {
    const { data } = await axios.get('/common/currency', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchStations = async (params = {}) => {
  try {
    const { data } = await axios.get('/ops-station/list-station-ops');
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchStaffs = async (params = {}) => {
  try {
    const { data } = await axios.get('/ops-staff/list-staff', { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getPartners = async (params) => {
  try {
    const { data } = await axios.get(`/op/partner/by-type?Type=CONNECT`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSteps = async (params) => {
  try {
    const { data } = await axios.get(`/api/crm/verify/steps`, {
      params: params
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchMyStaffs = async (params = {}) => {
  try {
    const { data } = await axios.get('/ops-staff/list-staff-by', {params});
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchCalculater = async (params = {}) => {
  try {
    let str = '';
    if(params && params.ServiceId && params.ServiceId.length) {
      params.ServiceId.forEach(id => {
        if(str) str += `&ServiceId=${id}`
        else str += `ServiceId=${id}`;
      });
    }
    delete params['ServiceId'];
    if(params && params.ChildItems && params.ChildItems.length) {
      params.ChildItems.forEach((item, index) => {
        if(str) str += `&ChildItems[${index}].Weight=${item.Weight}&ChildItems[${index}].Length=${item.Length}&ChildItems[${index}].Width=${item.Width}&ChildItems[${index}].Height=${item.Height}`
        else str += `ChildItems[${index}].Weight=${item.Weight}&ChildItems[${index}].Length=${item.Length}&ChildItems[${index}].Width=${item.Width}&ChildItems[${index}].Height=${item.Height}`;
      });
    }
    delete params['ChildItems'];
    const { data } = await axios.get(`/api/price-calculator?${str}`, { params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const calculaterExport = async (params = {}) => {
  try {
    const _params = {
      'ServiceCode': params.ServiceCode,
      'ToCountryOrTerritoryId': params.CountryId,
      'PackageType': params.PackageType || 'SPX',
      'GoodsValue': params.GoodsValue || 0,
      'NumberOfItems': params.NumberOfItems || 1,
      'Weight': params.Weight,
      'Dim.Length': params.Length,
      'Dim.Width': params.Width,
      'Dim.Height': params.Height,
      'AccountNr': params.AccountNr || '',
      'OtherFees': params.OtherFees || 0,
      'ChildItems': params.ChildItems || []
    }

    let str = '';
    if(_params && _params.ChildItems && _params.ChildItems.length) {
      _params.ChildItems.forEach((item, index) => {
        if(str) str += `&ChildItems[${index}].Weight=${item.Weight}&ChildItems[${index}].Length=${item.Length}&ChildItems[${index}].Width=${item.Width}&ChildItems[${index}].Height=${item.Height}`
        else str += `ChildItems[${index}].Weight=${item.Weight}&ChildItems[${index}].Length=${item.Length}&ChildItems[${index}].Width=${item.Width}&ChildItems[${index}].Height=${item.Height}`;
      });
    }
    delete _params['ChildItems'];
    const { data } = await axios.get(`/api/international-price-calculator?${str}`, { params: _params });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchCalculaterExtra = async (params = {}) => {
  try {
    let str = ''
    if(params.services && params.services.length) {
      params.services.forEach(id => {
        str += `&ExtraService=${id}`
      });
      const { data } = await axios.get(`/bill/calc-price-extra-service?${str}`, { params });
      return data;
    } else {
      return { Result: 0 }
    }

  } catch (error) {
    throw error;
  }
};

export const fetchBatch = async (CodeType) => {
  try {
    const { data } = await axios.get(`/api/util/gen-code?CodeType=${CodeType || 'CHECKIN_BATCH'}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchBillCheckin = async (params) => {
  try {
    const { data } = await axios.get('/api/op/check-in/check-bill', {params});
    return data;
  } catch (error) {
    throw error;
  }
};

export const getBillCheckined = async (params) => {
  try {
    const { data } = await axios.get('/api/op/check-in/get-bill-checkin', {params});
    return data;
  } catch (error) {
    throw error;
  }
};

export const getInternationalDeliveryPartners = async (params) => {
  try {
    const { data } = await axios.get('/api/common/international-delivery-partner', {params});
    return data;
  } catch (error) {
    throw error;
  }
};

export const getInternationalDeliveryZones = async (params) => {
  try {
    const { data } = await axios.get('/api/common/international-delivery-zone', {params});
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveCountry = async (params) => {
  try {
    const { data } = await axios.post('/api/country', params);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getCustomerDetail = async (customerId) => {
  try {
    const { data } = await axios.get(`crm/customer/full-info`, { params: {Id: customerId} });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getInStationStaffByDepartment = async (params = {}) => {
  try {
    const { data } = await axios.get('/api/staff/staff-with-department', {params});
    return data;
  } catch (error) {
    throw error;
  }
};

export const getDepartments = async (params = {}) => {
  try {
    const { data } = await axios.get('/department/list-department', {params});
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchFamilyRelationship = async (params = {}) => {
  try {
    const { data } = await API.get('/common/family', {params});
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchCertificateType = async (params = {}) => {
  try {
    const { data } = await API.get('/common/certificate-type', {params});
    return data;
  } catch (error) {
    throw error;
  }
};
export const fetchPeoples = async (params = {}) => {
  try {
    const { data } = await API.get('/common/people', {params});
    return data;
  } catch (error) {
    throw error;
  }
};
