import { createAction, handleActions } from 'redux-actions';
// - Actions
export const statisticsPickupsRequest = createAction('STATISTIC_PICKUP_REQUEST');
export const statisticsPickupsSuccess = createAction('STATISTIC_PICKUP_SUCCESS');
export const excelPickupsRequest = createAction('EXCEL_STATISTICS_PICKUP_REQUEST');

export const statisticsDeliveryRequest = createAction('STATISTIC_DELIVERY_REQUEST');
export const statisticsDeliverySuccess = createAction('STATISTIC_DELIVERY_SUCCESS');
export const excelDeliveryRequest = createAction('EXCEL_STATISTICS_DELIVERY_REQUEST');

export const statisticsQuantityRequest = createAction('STATISTIC_QUANTITY_REQUEST');
export const statisticsQuantitySuccess = createAction('STATISTIC_QUANTITY_SUCCESS');
export const excelQuantityRequest = createAction('EXCEL_STATISTICS_QUANTITY_REQUEST');

export const failure = createAction('REPORT_ACTION_FAILURE');

// - Initial State
export const initialState = {
  pickups: [],
  pickupsing: false,

  deliveries: [],
  deliveriesing: false,

  quantities: [],
  quantitiesing: false,

  pagination: {
    Page: 1,
    Size: 50,
    Total: 0
  },
  error: null,
};

// - Reducers
const reducer = handleActions({
  [statisticsPickupsRequest]: (state) => ({
    ...state, pickupsing: true,
  }),
  [statisticsPickupsSuccess]: (state, { payload: { pickups, pagination } }) => ({
    ...state, pickups: pickups, pagination: pagination, pickupsing: false,
  }),

  [statisticsDeliveryRequest]: (state) => ({
    ...state, deliveriesing: true,
  }),
  [statisticsDeliverySuccess]: (state, { payload: { deliveries, pagination } }) => ({
    ...state, deliveries: deliveries, pagination: pagination, deliveriesing: false,
  }),

  
  [statisticsQuantityRequest]: (state) => ({
    ...state, quantities:[], quantitiesing: true,
  }),
  [statisticsQuantitySuccess]: (state, { payload: { quantities, pagination } }) => ({
    ...state, quantities: quantities, pagination: pagination, quantitiesing: false,
  }),

  [failure]: (state, action) => ({
    ...state,
    error: action.error,
  }),
}, initialState);

export default reducer;
