import { call, put, takeLatest } from 'redux-saga/effects';
import * as absentService from '../../services/absent-service';
import { AbsentFormAction } from './action';
import { SubmissionError } from "redux-form";

import {
  getAbsentTypesSuccess,
  getAbsentStatisticSuccess,
  getListAbsentByStaffSuccess,
} from './reducer';

// Lấy danh sách loại đơn nghỉ phép
export function* fetchAbsentTypes({ payload }) {
  try {
    const { Result } = yield call(absentService.fetchAbsentTypes, payload);
    if(Result && Result.length) {
      yield put(getAbsentTypesSuccess({ absentTypes: Result }));
    } else {
      yield put(getAbsentTypesSuccess({ absentTypes: []}));
    }
  } catch (error) {
    yield put(getAbsentTypesSuccess({ absentTypes: [] }));
  }
}
// Thống kê ngày nghỉ phép của nhân viên
export function* fetchAbsentStatistic({ payload }) {
  try {
    const { Result } = yield call(absentService.fetchAbsentStatistic, payload);
    if (Result) {
      yield put(getAbsentStatisticSuccess({ absentStatistic: Result }));
    } else {
      yield put(getAbsentStatisticSuccess({ absentStatistic: null }));
    }
  } catch (error) {
    yield put(getAbsentStatisticSuccess({ absentStatistic: null }));
  }
}
// Lấy danh sách đơn nghỉ phép của nhân viên
export function* fetchAbsentsByStaff({ payload }) {
  try {
    const { Result } = yield call(absentService.fetchAbsentsByStaff, payload);
    if (Result.Data) {
      const { Data, Pagination } = Result
      yield put(getListAbsentByStaffSuccess({ absents: Data, pagination: Pagination }));
    } else {
      yield put(getListAbsentByStaffSuccess({ absents: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getListAbsentByStaffSuccess({ absents: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}
// Đăng ký đơn nghỉ phép
export function* saveAbsent({ payload }) {
  try {
    let { Result } = yield call(absentService.saveAbsent, payload);
    if (Result) {
      yield put(AbsentFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Có lỗi trong quá trình thêm dữ liệu!'
      });
      yield put(AbsentFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Có lỗi xảy ra!";
    if (error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({ _error: _message });
    yield put(AbsentFormAction.failure(formError));
  }
}
// Xóa đơn nghỉ phép
export function* deleteAbsent({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(absentService.deleteAbsent, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}
// Duyệt đơn nghỉ phép
export function* approvalEmployeeAbsentForm({ payload: { data, cb } }) {
  try {
    let { Result } = yield call(absentService.approvalEmployeeAbsentForm, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}
// Từ chối đơn nghỉ phép
export function* rejectEmployeeAbsentForm({ payload: { data, cb } }) {
  try {
    let { Result } = yield call(absentService.rejectEmployeeAbsentForm, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}
export default [
  takeLatest('GET_ABSENT_TYPE_LIST_REQUEST', fetchAbsentTypes),
  takeLatest('GET_ABSENT_STATISTIC_REQUEST', fetchAbsentStatistic),
  takeLatest('GET_LIST_ABSENT_BY_STAFF_REQUEST', fetchAbsentsByStaff),
  takeLatest(AbsentFormAction.REQUEST, saveAbsent),
  takeLatest('DELETE_ABSENT_REQUEST', deleteAbsent),
  takeLatest('APPROVAL_EMPLOYEE_ABSENT_FORM_REQUEST', approvalEmployeeAbsentForm),
  takeLatest('REJECT_EMPLOYEE_ABSENT_FORM_REQUEST', rejectEmployeeAbsentForm),
];
