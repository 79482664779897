import { createAction, handleActions } from "redux-actions";
// - Actions
export const searchUsersRequest = createAction("USER_LIST_SEARCH_REQUEST");
export const searchUsersSuccess = createAction("USER_LIST_SEARCH_SUCCESS");

export const searchStaffRequest = createAction('SEARCH_STAFF_REQUEST');
export const searchStaffSuccess = createAction('SEARCH_STAFF_SUCCESS');

export const searchMyStaffRequest = createAction('SEARCH_MY_STAFF_REQUEST');
export const searchMyStaffSuccess = createAction('SEARCH_MY_STAFF_SUCCESS');

export const searchStationStaffRequest = createAction('SEARCH_STATION_STAFF_REQUEST');
export const searchStationStaffSuccess = createAction('SEARCH_STATION_STAFF_SUCCESS');

export const getStaffByCodeRequest = createAction('GET_STAFF_BY_CODE_REQUEST');
export const getStaffByCodeSuccess = createAction('GET_STAFF_BY_CODE_SUCCESS');

export const getUserRequest = createAction("GET_USER_REQUEST");
export const getUserSuccess = createAction("GET_USER_SUCCESS");

export const getAvatarRequest = createAction("GET_AVATAR_REQUEST");
export const getAvatarSuccess = createAction("GET_AVATAR_SUCCESS");

export const loadAddressRequest = createAction("LOAD_ADDRESS_REQUEST");
export const loadAddressSuccess = createAction("LOAD_ADDRESS_SUCCESS");

export const deleteAddressRequest = createAction('ADDRESS_DELETE_REQUEST');

export const getNotificationsRequest = createAction('GET_SCHEDULES_REQUEST');
export const getNotificationsSuccess = createAction('GET_SCHEDULES_SUCCESS');
export const saveNotificationRequest = createAction('SAVE_SCHEDULE_REQUEST');

export const failure = createAction("USER_ACTION_FAILURE");

// - Initial State
export const initialState = {
  users: [],
  pagination: {
    pages: 1,
    page: 50,
    total: 0
  },
  loading: false,
  avatar: null,

  user: null,
  usering: false,

  address: [],
  addressing: false,

  schedules: null,
  schedulesing: false,
  scheduletypes: [],

  error: null
};

// - Reducers
const reducer = handleActions(
  {
    [searchUsersRequest]: state => ({
      ...state, loading: true
    }),
    [searchUsersSuccess]: (state, { payload: { users, pagination } }) => ({
      ...state, users: users, pagination: pagination, loading: false
    }),

    [getUserRequest]: state => ({
      ...state, usering: true
    }),
    [getUserSuccess]: (state, { payload: { user } }) => ({
      ...state, user: user, usering: false
    }),

    [getAvatarRequest]: state => ({
      ...state, avatar: null
    }),
    [getAvatarSuccess]: (state, { payload: { avatar } }) => ({
      ...state, avatar: avatar
    }),

    [searchStaffRequest]: (state) => {
      return { ...state, searching: true };
    },
    [searchStaffSuccess]: (state, { payload: { staffs } }) => {
      return { ...state, staffs: staffs || [], searching: false };
    },
  
    [searchMyStaffRequest]: (state) => {
      return { ...state, mystaffing: true };
    },
    [searchMyStaffSuccess]: (state, { payload: { staffs } }) => {
      return { ...state, myStaffs: staffs || [], mystaffing: false };
    },
  
    [searchStationStaffRequest]: (state) => {
      return { ...state, stationStaffing: true };
    },
    [searchStationStaffSuccess]: (state, { payload: { staffs } }) => {
      return { ...state, stationStaffs: staffs || [], stationStaffing: false };
    },
  
    [getStaffByCodeRequest]: (state) => {
      return { ...state, staffcoding: true };
    },
    [getStaffByCodeSuccess]: (state, { payload: { staffs } }) => {
      return { ...state, staffscode: staffs || [], staffcoding: false };
    },
  

    [failure]: (state, action) => ({
      ...state,
      loading: false,
      contacting: false,
      error: action.error
    })
  },
  initialState
);

export default reducer;
