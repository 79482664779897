import { call, put, takeLatest } from 'redux-saga/effects';
import * as profileServices from '../../services/profile-service';
import { CertificateFormAction, ContactFormAction, FamilyFormAction, FileFormAction, ListEmployeeFormAction, PreferenceFormAction, ProfileFormAction, WorkingFormAction } from './action';
import { SubmissionError } from "redux-form";
import moment from 'moment';

import {
  getGeneralByCodeSuccess,
  getInfoJobByCodeSuccess,
  getContactByCodeSuccess,
  getListFamilyByCodeSuccess,
  getListWorkingByCodeSuccess,
  getListContractByCodeSuccess,
  getPreferenceByCodeSuccess,
  getListCertificateByCodeSuccess,
  getListAwardByCodeSuccess,
  getListFileByCodeSuccess,
  getListSearchContactSuccess,
  getListEmployeeSuccess,
  getProfileByStaffSuccess
} from './reducer';

// Lấy thông tin chung
export function* fetchGeneral({ payload }) {
  try {
    const { Result } = yield call(profileServices.fetchGeneral, payload);
    if (Result) {
      yield put(getGeneralByCodeSuccess({ general: Result }));
    } else {
      yield put(getGeneralByCodeSuccess({ general: null }));
    }
  } catch (error) {
    yield put(getGeneralByCodeSuccess({ general: null }));
  }
}

// Lấy thông tin công việc
export function* fetchInfoJob({ payload }) {
  try {
    const { Result } = yield call(profileServices.fetchInfoJob, payload);
    if (Result) {
      yield put(getInfoJobByCodeSuccess({ infoJob: Result }));
    } else {
      yield put(getInfoJobByCodeSuccess({ infoJob: null }));
    }
  } catch (error) {
    yield put(getInfoJobByCodeSuccess({ infoJob: null }));
  }
}

// Lấy thông tin liên hệ
export function* fetchContact({ payload }) {
  try {
    const { Result } = yield call(profileServices.fetchContact, payload);
    if (Result) {
      yield put(getContactByCodeSuccess({ contact: Result }));
    } else {
      yield put(getContactByCodeSuccess({ contact: null }));
    }
  } catch (error) {
    yield put(getContactByCodeSuccess({ contact: null }));
  }
}
// Cập nhật thông tin liên hệ
export function* saveContact({ payload }) {
  try {
    let { Result } = yield call(profileServices.saveContact, payload);
    if (Result) {
      yield put(ContactFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Có lỗi trong quá trình thêm dữ liệu!'
      });
      yield put(ContactFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Có lỗi xảy ra!";
    if (error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({ _error: _message });
    yield put(ContactFormAction.failure(formError));
  }
}
// Lấy list thông tin gia đình
export function* fetchFamilies({ payload }) {
  try {
    const { Result } = yield call(profileServices.fetchFamilies, payload);
    if (Result.Data) {
      const { Data, Pagination } = Result
      yield put(getListFamilyByCodeSuccess({ families: Data, pagination: Pagination }));
    } else {
      yield put(getListFamilyByCodeSuccess({ families: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getListFamilyByCodeSuccess({ families: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}
// Insert Or Update Family
export function* saveFamily({ payload }) {
  try {
    payload.FAMILY_BIRTH = moment(payload.FAMILY_BIRTH).format('DD/MM/YYYY HH:mm:ss');
    payload.FAMILY_DEPENDENT_END_DATE = moment(payload.FAMILY_DEPENDENT_END_DATE).format('DD/MM/YYYY HH:mm:ss');
    payload.FAMILY_DEPENDENT_START_DATE = moment(payload.FAMILY_DEPENDENT_START_DATE).format('DD/MM/YYYY HH:mm:ss');
    let { Result } = yield call(profileServices.saveFamily, payload);
    if (Result) {
      yield put(FamilyFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Có lỗi trong quá trình thêm dữ liệu!'
      });
      yield put(FamilyFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Có lỗi xảy ra!";
    if (error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({ _error: _message });
    yield put(FamilyFormAction.failure(formError));
  }
}

// Xóa family
export function* deleteFamily({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(profileServices.deleteFamily, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

// Lấy list kinh nghiệm làm việc
export function* fetchWorkings({ payload }) {
  try {
    const { Result } = yield call(profileServices.fetchWorkings, payload);
    if (Result.Data) {
      const { Data, Pagination } = Result
      yield put(getListWorkingByCodeSuccess({ workings: Data, pagination: Pagination }));
    } else {
      yield put(getListWorkingByCodeSuccess({ workings: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getListWorkingByCodeSuccess({ workings: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}
export function* saveWorking({ payload }) {
  try {
    payload.WORKING_START_DATE = moment(payload.WORKING_START_DATE).format('DD/MM/YYYY HH:mm:ss');
    payload.WORKING_END_DATE = moment(payload.WORKING_END_DATE).format('DD/MM/YYYY HH:mm:ss');
    let { Result } = yield call(profileServices.saveWorking, payload);
    if (Result) {
      yield put(WorkingFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Có lỗi trong quá trình thêm dữ liệu!'
      });
      yield put(WorkingFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Có lỗi xảy ra!";
    if (error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({ _error: _message });
    yield put(WorkingFormAction.failure(formError));
  }
}
// Xóa kinh nghiệm làm việc
export function* deleteWorking({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(profileServices.deleteWorking, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}
// Lấy list hợp đồng
export function* fetchContracts({ payload }) {
  try {
    const { Result } = yield call(profileServices.fetchContracts, payload);
    if (Result.Data) {
      const { Data, Pagination } = Result
      yield put(getListContractByCodeSuccess({ contracts: Data, pagination: Pagination }));
    } else {
      yield put(getListContractByCodeSuccess({ contracts: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getListContractByCodeSuccess({ contracts: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}


// Lấy thông tin kỹ năng - sở thích
export function* fetchPreference({ payload }) {
  try {
    const { Result } = yield call(profileServices.fetchPreference, payload);
    if (Result) {
      yield put(getPreferenceByCodeSuccess({ preference: Result }));
    } else {
      yield put(getPreferenceByCodeSuccess({ preference: null }));
    }
  } catch (error) {
    yield put(getPreferenceByCodeSuccess({ preference: null }));
  }
}
// Cập nhật Kỹ năng - Sở thích
export function* savePreference({ payload }) {
  try {
    let { Result } = yield call(profileServices.savePreference, payload);
    if (Result) {
      yield put(PreferenceFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Có lỗi trong quá trình thêm dữ liệu!'
      });
      yield put(PreferenceFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Có lỗi xảy ra!";
    if (error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({ _error: _message });
    yield put(PreferenceFormAction.failure(formError));
  }
}

// Lấy list chứng chỉ
export function* fetchCertificates({ payload }) {
  try {
    const { Result } = yield call(profileServices.fetchCertificates, payload);
    if (Result.Data) {
      const { Data, Pagination } = Result
      yield put(getListCertificateByCodeSuccess({ certificates: Data, pagination: Pagination }));
    } else {
      yield put(getListCertificateByCodeSuccess({ certificates: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getListCertificateByCodeSuccess({ certificates: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}
// Insert Update Chứng chỉ
export function* saveCertificate({ payload }) {
  try {
    payload.StartDate = moment(payload.StartDate).format('DD/MM/YYYY HH:mm:ss');
    payload.EndDate = moment(payload.EndDate).format('DD/MM/YYYY HH:mm:ss');
    let { Result } = yield call(profileServices.saveCertificate, payload);
    if (Result) {
      yield put(CertificateFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Có lỗi trong quá trình thêm dữ liệu!'
      });
      yield put(CertificateFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Có lỗi xảy ra!";
    if (error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({ _error: _message });
    yield put(CertificateFormAction.failure(formError));
  }
}
// Xóa family
export function* deleteCertificate({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(profileServices.deleteCertificate, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

// Lấy list khen thưởng
export function* fetchAwards({ payload }) {
  try {
    const { Result } = yield call(profileServices.fetchAwards, payload);
    if (Result.Data) {
      const { Data, Pagination } = Result
      yield put(getListAwardByCodeSuccess({ awards: Data, pagination: Pagination }));
    } else {
      yield put(getListAwardByCodeSuccess({ awards: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getListAwardByCodeSuccess({ awards: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}

// Lấy list giấy tờ
export function* fetchFiles({ payload }) {
  try {
    const { Result } = yield call(profileServices.fetchFiles, payload);
    if (Result.Data) {
      const { Data, Pagination } = Result
      yield put(getListFileByCodeSuccess({ files: Data, pagination: Pagination }));
    } else {
      yield put(getListFileByCodeSuccess({ files: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getListFileByCodeSuccess({ files: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}
// Insert Update giấy tờ
export function* saveFile({ payload }) {
  try {
    let { Result } = yield call(profileServices.saveFile, payload);
    if (Result) {
      yield put(FileFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Có lỗi trong quá trình thêm dữ liệu!'
      });
      yield put(FileFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Có lỗi xảy ra!";
    if (error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({ _error: _message });
    yield put(FileFormAction.failure(formError));
  }
}
// Xóa Giấy tờ
export function* deleteFile({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(profileServices.deleteFile, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}
// Lấy list danh bạ nhân viên
export function* fetchSearchContacts({ payload }) {
  try {
    const { Result } = yield call(profileServices.fetchSearchContacts, payload);
    if (Result.Data) {
      const { Data, Pagination } = Result
      yield put(getListSearchContactSuccess({ searchContacts: Data, pagination: Pagination }));
    } else {
      yield put(getListSearchContactSuccess({ searchContacts: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getListSearchContactSuccess({ searchContacts: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}

// Lấy list hồ sơ nhân viên
export function* fetchListEmployee({ payload }) {
  try {
    const { Result } = yield call(profileServices.fetchListEmployee, payload);
    if (Result.Data) {
      const { Data, Pagination } = Result
      yield put(getListEmployeeSuccess({ listEmployee: Data, pagination: Pagination }));
    } else {
      yield put(getListEmployeeSuccess({ listEmployee: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
    }
  } catch (error) {
    yield put(getListEmployeeSuccess({ listEmployee: [], pagination: { Page: 1, Size: 50, Total: 0 } }));
  }
}

// Lưu thông tin hồ sơ
export function* saveProfile({ payload }) {
  try {
    let { Result } = yield call(profileServices.saveProfile, payload);
    if (Result) {
      yield put(ProfileFormAction.success(Result));
    } else {
      const formError = new SubmissionError({
        _error: 'Có lỗi trong quá trình thêm dữ liệu!'
      });
      yield put(ProfileFormAction.failure(formError));
    }
  } catch (error) {
    let _message = "Có lỗi xảy ra!";
    if (error && typeof error.Description === 'string') {
      _message = error.Description;
    }
    const formError = new SubmissionError({ _error: _message });
    yield put(ProfileFormAction.failure(formError));
  }
}

// Xóa hồ sơ
export function* deleteProfile({ payload: { data, cb } }) {
  try {
    const { Result } = yield call(profileServices.deleteProfile, data);
    if (Result) {
      cb(true)
    } else {
      cb(false)
    }
  } catch (error) {
    cb(false)
  }
}

// Lấy thông tin hồ sơ theo nhân viên
export function* fetchProfileByStaff({ payload }) {
  try {
    const { Result } = yield call(profileServices.fetchProfileByStaff, payload);
    if (Result) {
      yield put(getProfileByStaffSuccess({ profileByStaff: Result }));
    } else {
      yield put(getProfileByStaffSuccess({ profileByStaff: null }));
    }
  } catch (error) {
    yield put(getProfileByStaffSuccess({ profileByStaff: null }));
  }
}

export default [
  takeLatest('GET_GENERAL_BY_CODE_REQUEST', fetchGeneral),
  takeLatest('GET_INFO_JOB_BY_CODE_REQUEST', fetchInfoJob),
  takeLatest('GET_CONTACT_BY_CODE_REQUEST', fetchContact),
  takeLatest(ContactFormAction.REQUEST, saveContact),
  takeLatest('GET_LIST_FAMILY_BY_CODE_REQUEST', fetchFamilies),
  takeLatest(FamilyFormAction.REQUEST, saveFamily),
  takeLatest('DELETE_FAMILY_REQUEST', deleteFamily),
  takeLatest('GET_LIST_WORKING_BY_CODE_REQUEST', fetchWorkings),
  takeLatest(WorkingFormAction.REQUEST, saveWorking),
  takeLatest('DELETE_WORKING_REQUEST', deleteWorking),
  takeLatest('GET_LIST_CONTRACT_BY_CODE_REQUEST', fetchContracts),
  takeLatest('GET_LIST_CERTIFICATE_BY_CODE_REQUEST', fetchCertificates),
  takeLatest(CertificateFormAction.REQUEST, saveCertificate),
  takeLatest('DELETE_CERTIFICATE_REQUEST', deleteCertificate),
  takeLatest('GET_PREFERENCE_BY_CODE_REQUEST', fetchPreference),
  takeLatest(PreferenceFormAction.REQUEST, savePreference),
  takeLatest('GET_LIST_AWARD_BY_CODE_REQUEST', fetchAwards),
  takeLatest('GET_LIST_FILE_BY_CODE_REQUEST', fetchFiles),
  takeLatest(FileFormAction.REQUEST, saveFile),
  takeLatest('DELETE_FILE_REQUEST', deleteFile),
  takeLatest('GET_LIST_SEARCH_CONTACT_REQUEST', fetchSearchContacts),
  takeLatest('GET_LIST_EMPLOYEE_REQUEST', fetchListEmployee),
  takeLatest(ProfileFormAction.REQUEST, saveProfile),
  takeLatest('DELETE_EMPLOYEE_PROFILE_REQUEST', deleteProfile),
  takeLatest('GET_PROFILE_BY_STAFF_REQUEST', fetchProfileByStaff),
];
